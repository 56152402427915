import * as axios from "axios";
import {AUTH_URL, SERVICE_URL, STAT_URL} from "./apiUrls";

const instance = (url) => {
    return axios.create({
        headers: {
            'Content-Type': 'application/json'
        },
        baseURL: url
    })
}

export const authApi = {
    login(username, password) {
        return instance(AUTH_URL).post(`v1/auth`, {username, password})
    },
    logout() {
        let token = localStorage.getItem('token')
        return instance(AUTH_URL).post(`v1/logout?token=${token}`)
    },
    refresh() {
        let refresh = localStorage.getItem('refresh')
        return instance(AUTH_URL).post(`v1/auth/refresh?refresh=${refresh}`)
    },
    me() {
        return RequestHandler(() => {
            let token = localStorage.getItem('token')
            return instance(AUTH_URL).get(`v1/me?token=${token}`);
        })
    }
}

export const mainApi = {
    requestSupport(text) {
        let token = localStorage.getItem('token')
        return instance(SERVICE_URL).get(`v1/offer/tickets/create?token=${token}&text=${text}`)
    }
}

export const dataApi = {
    getCourses() {
        return RequestHandler(() => {
            let token = localStorage.getItem('token')
            return instance(SERVICE_URL).get(`v1/me/courses?token=${token}`);
        })
    },
    getNotifications() {
        return RequestHandler(() => {
            let token = localStorage.getItem('token')
            return instance(SERVICE_URL).get(`v1/offer/news?page=1&perPage=10&token=${token}`);
        })
    },
    readNotification(id) {
        return RequestHandler(() => {
            let token = localStorage.getItem('token')
            return instance(SERVICE_URL).get(`v1/offer/news/read?id=${id}&token=${token}`);
        })
    },
    readAllNotifications() {
        return RequestHandler(() => {
            let token = localStorage.getItem('token')
            return instance(SERVICE_URL).get(`v1/offer/news/read-all?token=${token}`);
        })
    },
    getTranslation(path, lang) {
        return instance(SERVICE_URL).get(`v1/translations?page=1&perPage=100&lang=${lang}&path=${path}`);

    },
    addWordToTranslate(path, word, lang) {
        let token = localStorage.getItem('token')
            return instance(SERVICE_URL).get(`v1/offer/translate?lang=${lang}&word=${word}&path=${path}&token=${token}`);
    },
    queryTrafficByTime(query) {
        return RequestHandler(() => {
            query.token = localStorage.getItem('token')
            query.interval = 'day'
            return instance(STAT_URL).get(`v1/offer/statistic/traffic/byTime?${objectToQuery(query)}`);
        })
    },
    uploadOffers(query) {
        return RequestHandler(() => {
            query.token = localStorage.getItem('token');
            return instance(STAT_URL).get(`v1/offer/statistic/traffic/upload?${objectToQuery(query)}`)
        })
    },
    queryOffers(query){
        return RequestHandler(() => {
            query.token = localStorage.getItem('token');
            return instance(STAT_URL).get(`v1/offer/statistic/traffic/query?${objectToQuery(query)}`)
        })
    },
    clearOffers(hash) {
        let token = localStorage.getItem('token')
        return RequestHandler(() => {
            return instance(STAT_URL).get(`v1/offer/statistic/traffic/clear?token=${token}&hash=${hash}`)
        })
    },
    getTableOffers(dataFrom, dataTo, paginateNum, NumberShowOffers, queryString) {
        let token = localStorage.getItem('token')
        return RequestHandler(() => {
            return instance(STAT_URL).get(`v1/offer/postbacks?token=${token}&orderBy=time&orderDir=DESC&query[date_from]=${dataFrom}&query[date_to]=${dataTo}&perPage=${NumberShowOffers}&page=${paginateNum}${queryString}`);
        })
    },
    exportPostback(query) {
        return RequestHandler(() => {
            query.token = localStorage.getItem('token');
            return instance(STAT_URL).get(`v1/offer/postbacks/export?${objectToQuery(query)}`);
        })
    }
}



export const websApi = {
    uploadWebs(query) {
        return RequestHandler(() => {
            query.token = localStorage.getItem('token');
            return instance(STAT_URL).get(`v1/offer/statistic/web/upload?${objectToQuery(query)}`);
        })
    },
    queryWebs(hash, page = 1, perPage = 1, sort) {
        return RequestHandler(() => {
            let token = localStorage.getItem('token')
            return instance(STAT_URL).get(`v1/offer/statistic/web/query?token=${token}&hash=${hash}&orderBy=${sort.name}&orderDir=${sort.dir}&page=${page}&perPage=${perPage}`);
        })
    },
    clearWebs(hash) {
        return RequestHandler(() => {
            let token = localStorage.getItem('token')
            localStorage.removeItem('hashWebs')
            return instance(STAT_URL).get(`v1/offer/statistic/web/clear?token=${token}&hash=${hash}`);
        })
    },
    exportWebs(query) {
        return RequestHandler(() => {
            query.hash = localStorage.getItem('hashWebs')
            query.token = localStorage.getItem('token')
            return instance(STAT_URL).get(`v1/offer/statistic/web/export?${objectToQuery(query)}`);
        })
    },
    blockWeb(text, blockedId) {
        return RequestHandler(() => {
            let token = localStorage.getItem('token')
            return instance(SERVICE_URL).get(`v1/offer/tickets/block-user?token=${token}&text=${text}&block_partner_id=${blockedId}`);
        })
    },
    websTotal(hash) {
        return RequestHandler(() => {
            let token = localStorage.getItem('token')
            return instance(STAT_URL).get(`v1/offer/statistic/web/total?token=${token}&hash=${hash}`);
        })
    }
}

function waitForTokenRefresh(rslv = null) {
    return new Promise(function (resolve) {

        resolve = rslv ? rslv : resolve;

        if (!RequestHandler.isTokenValid) {

            setTimeout(() => {
                waitForTokenRefresh(resolve);
            }, 500)

        } else {
            resolve();
        }

    });
}

async function RequestHandler(method, ...args) {

    let res;
    let errorStatus = null;

    try {
        if (!RequestHandler.isTokenValid) {
            await waitForTokenRefresh();
        }
        res = await method(...args);
    } catch (e) {
        errorStatus = e.response.status;
        res = e.response.data;
    }

    if (res && res.status === "token_expired") {
        console.log('expired')
        if (RequestHandler.isTokenValid) {

            RequestHandler.isTokenValid = false;
            await authApi.refresh()
                .then(response => {
                    RequestHandler.isTokenValid = true;
                    localStorage.setItem('token', response.data.data.token)
                    localStorage.setItem('refresh', response.data.data.refresh)
                    localStorage.setItem('data', response.data.data.created)
                })
                .catch(err => {
                    localStorage.clear();
                    document.location.reload();
                    if (err.response) {
                        console.log("Response error")
                    } else if (err.request) {
                        console.log("Request error")
                    } else {
                        console.log("Unknown error")
                    }
                })

        }

        await waitForTokenRefresh();

        return method(...args);

    }
    if (errorStatus === 401) {
        localStorage.clear();
        document.location.reload();
    }

    return res;
}

RequestHandler.isTokenValid = true;

/**
 * Convert object to query.
 * @param obj
 * @param pref
 * @returns {string}
 */
function objectToQuery(obj, pref = []) {
    if (Object.keys(obj).length === 0) {
        return ''
    }
    let res = [];
    let isArray = Array.isArray(obj);
    for (let k in obj) {

        if (obj.hasOwnProperty(k)) {

            if (typeof obj[k] === "object" && obj[k] !== null) {

                res.push(objectToQuery(obj[k], [...pref, k]));

            } else if (typeof obj[k] !== "undefined") {

                let prefKey = [...pref, k].map((e, i) => i === 0 ? e : `[${e}]`).join("");

                res.push(`${encodeURIComponent(prefKey)}=${encodeURIComponent(obj[k])}`);

            }

        }

    }

    return res.join("&");
}