import {GET_BANNERS_OFFER} from './types'

const initialState = {
    offers: {},
    total: 0
};
export const bannersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BANNERS_OFFER:
            return {...state, offers: action.payload.offers, total:action.payload.total};
        default:
            return state;
    }
};
