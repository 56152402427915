import React from 'react';
import { render } from 'react-dom';
import App from './App';
import thunk from 'redux-thunk';
import {compose, createStore, applyMiddleware} from 'redux';
import {rootReducer} from './store/rootReducer';
import {Provider} from 'react-redux';
import './assets/vars.css';
import './index.css';


const initialState = {};
export const store = createStore(
    rootReducer,
    initialState,
    compose(
        // thunk
        applyMiddleware(
            thunk
        ),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);
Array.prototype.isEmpty = function() {
    return this.length > 0;
};

const app = (
    <Provider store={store}>
        <App/>
    </Provider>
);

render(app, document.getElementById('root')
);

