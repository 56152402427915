import React from 'react'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const Page404 = () => {
    return (
        <>
            <Header/>
            <div className="main">
                <div className="container">
                    404 Not found
                </div>
            </div>
            <Footer/>
        </>

    )
}

export default Page404