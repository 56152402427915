import views from './views'
import {
    HOME_PATH,
    HOME_PATH_PID,
    WEBS_PATH,
    WEBS_PAGE_PATH,
    DOCUMENTS_PATH,
    DOCUMENTS_PAGE_PATH,
    BANNERS_PATH,
    BANNERS_PAGE_PATH,
    HISTORY_PATH,
    HISTORY_PAGE_PATH,
    ERROR_PAGE_404_PATH,
    LOGIN_PATH
} from './path'

export const LOGIN = {
    component: views.LoginPage,
    path: LOGIN_PATH,
    exact: true,
}

export const HOME = {
    component: views.HomePage,
    path: HOME_PATH,
    exact: true,
};

export const HOME_PID = {
    component: views.HomePage,
    path: HOME_PATH_PID,
    exact: true,
};
export const WEBS = {
    component: views.WebsPage,
    path: WEBS_PATH,
    exact: true,
};
export const WEBS_PAGE = {
    component: views.WebsPage,
    path: WEBS_PAGE_PATH,
    exact: true
};
export const DOCUMENTS = {
    component: views.DocumentsPage,
    path: DOCUMENTS_PATH,
    exact: true,
};
export const DOCUMENTS_PAGE = {
    component: views.DocumentsPage,
    path: DOCUMENTS_PAGE_PATH,
    exact: true
};
export const BANNERS = {
    component: views.BannersPage,
    path: BANNERS_PATH,
    exact: true,
};
export const BANNERS_PAGE = {
    component: views.BannersPage,
    path: BANNERS_PAGE_PATH,
    exact: true,
};
export const HISTORY = {
    component: views.HistoryPage,
    path: HISTORY_PATH,
    exact: true,
};
export const HISTORY_PAGE = {
    component: views.HistoryPage,
    path: HISTORY_PAGE_PATH,
    exact: true,
};
export const ERROR_PAGE_404 = {
    component: views.Page404,
    path: ERROR_PAGE_404_PATH,
    exact: false,
};

export default [
    HOME,
    HOME_PID,
    WEBS,
    WEBS_PAGE,
    DOCUMENTS,
    DOCUMENTS_PAGE,
    BANNERS,
    BANNERS_PAGE,
    HISTORY,
    HISTORY_PAGE,
    LOGIN,
    ERROR_PAGE_404
]