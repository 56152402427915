import React from 'react'
import classes from './Footer.module.css'
import Container from "react-bootstrap/Container";
import {getTranslatedWord} from "../../store/actions";
import {useSelector} from "react-redux";
import {GENERAL} from "../../store/types";

const Footer = (props) => {
    const translation = useSelector(state => state.data.translation)
    const path = GENERAL


    return (
        <footer className={classes.footer}>
            <Container fluid='md'>
                <div className={`${classes.footer__info} d-flex justify-content-between`}>
                    <div>
                        PDL Profit, 2020. {getTranslatedWord(path,'Все права защищены.')}
                    </div>
                    <a href="/#" className={classes.confidentiality}>{getTranslatedWord(path,'Политика конфиденциальности')}</a>
                </div>
            </Container>
        </footer>
    )
}

export default Footer;