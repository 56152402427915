import {GET_DOCUMENTS_OFFER} from './types'

const initialState = {
    offers: {},
    total: 0
};

export const documentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DOCUMENTS_OFFER:
            return {...state, offers: action.payload.offers, total: action.payload.total};
        default:
            return state;
    }
};