import Modal from "react-bootstrap/Modal";
import React from "react";
import {maxLengthCreator, minLengthCreator, required} from "../../../../utils/validators/validators";
import {Textarea} from "../../../FormControls/FormControls";
import classes from "../ModalSupport/ModalSupport.module.css";
import {Field, reduxForm} from "redux-form";
import {blockWeb, getTranslatedWord} from "../../../../store/actions";
import {GENERAL} from "../../../../store/types";

const path = GENERAL
const minLength3 = minLengthCreator(3)
const maxLength200 = maxLengthCreator(200)


const ModalBlockWeb = ({onHide, show, blockedId, dispatch, isWebBlocked}) => {
    const onSubmit = (text) => {
        dispatch(blockWeb(text.message, blockedId))
    }
    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton/>
        <Modal.Body>
            <h3>{getTranslatedWord(path, 'Отправка заявки')}</h3>
            {
                isWebBlocked ?
                    <>
                        <p>{getTranslatedWord(path, 'Спасибо, мы рассмотрим Вашу заявку как можно скорее')}</p>
                        <button className={classes.buttonOk} onClick={() => onHide(false)}>Ok</button>
                    </>

                    :
                    <ModalBlockReduxForm onSubmit={onSubmit}/>
            }

        </Modal.Body>
    </Modal>
}


const ModalBlockForm = (props) => {
    return (
        <form onSubmit={props.handleSubmit}>
            <Field
                validate={[required, minLength3, maxLength200]}
                component={Textarea}
                name="message"
                className={classes.request__message}
                placeholder={getTranslatedWord(path, 'Укажите причину блокировки')}/>
            <button className="primary-button">{getTranslatedWord(path, 'Отправить заявку')}</button>
        </form>
    )
}

const ModalBlockReduxForm = reduxForm({form: 'blockWeb'})(ModalBlockForm)

export default ModalBlockWeb