import React from 'react'
import Select from 'react-select'
import {getTranslatedWord} from "../../store/actions";
import './Select.css'
import {GENERAL} from "../../store/types";


const Select2 = (props) => {
    const path = GENERAL;

    let translatedWord = getTranslatedWord(path, 'на странице')

    const options = [
        {value: '50', label: `50 ${translatedWord}`},
        {value: '40', label: `40 ${translatedWord}`},
        {value: '30', label: `30 ${translatedWord}`},
        {value: '20', label: `20 ${translatedWord}`},
        {value: '10', label: `10 ${translatedWord}`},
    ];
    return <Select isSearchable={false} menuPlacement='top' defaultValue={options[0]}
                   onChange={event => props.onChangeCallBack(event.value)} classNamePrefix='filter' options={options}/>
}

export default Select2;