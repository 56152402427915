export const HOME_PATH = '/';
export const HOME_PATH_PID = '/:pid/';
export const WEBS_PATH = '/webs';
export const WEBS_PAGE_PATH = '/webs/:page/';
export const DOCUMENTS_PATH = '/documents';
export const DOCUMENTS_PAGE_PATH = '/documents/:page/';
export const BANNERS_PATH = '/banners';
export const BANNERS_PAGE_PATH = '/banners/:page/';
export const HISTORY_PATH = '/history';
export const HISTORY_PAGE_PATH = '/history/:page/';
export const ERROR_PAGE_404_PATH = '*';
export const LOGIN_PATH = '/login';
