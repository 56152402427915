import React, {useState, useEffect} from 'react'
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import {useDispatch, useSelector} from 'react-redux';
import {getTranslatedWord, getTranslation} from '../../store/actions';
import {Redirect, useHistory} from "react-router-dom";
import {useParams} from "react-router-dom";

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarSubmenu from "../../components/Sidebar/SidebarSubmenu/SidebarSubmenu";
import Footer from "../../components/Footer/Footer";
import DatePicker from "../../components/DateRangePicker/DatePicker";
import Pagination from "../../components/Pagination/Pagination";
import Select2 from "../../components/Select/Select";

import classes from './BannersPage.module.css'
import {compose} from "redux";
import {withAuthRedirect} from "../../hoc/withAuthRedirect";
import {BANNERS} from "../../store/types";

const BannersPage = () => {
    let now = 30;
    let params = useParams();
    const lang = useSelector(state => state.data.lang)
    const path = BANNERS;


    let page = params.page;
    const dispatch = useDispatch();
    const history = useHistory();
    const [NumberShowOffers, setNumberShowOffers] = useState(50);
    const [show, setShow] = useState(false);
    const [ActiveSidebar, setActiveSidebar] = useState(false);

    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);

    const changeSidebar = () => {
        setActiveSidebar(!ActiveSidebar);
    };

    const changeNumberShowOffers = (num) => {
        setNumberShowOffers(Number(num));
    };

    const handlePageClick = (data) => {
        let page = Number(data.selected) + 1;
        const target = `/banners/${page}/`;
        history.push(target)
    };

    const offers = useSelector(state => state.banners.offers);
    const total = useSelector(state => state.banners.total);
    const startDate = useSelector(state => state.data.date.dateStart)
    const endDate = useSelector(state => state.data.date.dateEnd)

    useEffect(() => {
        dispatch(getOffersForBanners({'count': NumberShowOffers}))
    }, [NumberShowOffers, page]);

    useEffect(() => {
        dispatch(getTranslation(path, lang))
    }, [lang])

    return (
        <>

            <Header/>
            <Sidebar ActiveSidebar={ActiveSidebar} changeSidebar={changeSidebar}/>
            <SidebarSubmenu ActiveSidebar={ActiveSidebar} changeSidebar={changeSidebar}/>

            {
                offers.length > 0 ?
                    <>
                        <Redirect to="/banners/1"/>
                        <main className={`main ${ActiveSidebar ? 'toggled' : ""}`}>
                            <Container fluid='md'>
                                <div className={classes.banners__wrapper}>
                                    <h2>{getTranslatedWord(path,'Баннеры')}</h2>
                                    <div className="main__content">
                                        <div className="data__config d-md-flex justify-content-between">
                                            <div className="data__export">
                                                <button className="primary-button" onClick={modalShow}>
                                                    {getTranslatedWord(path,'Экспорт данных')}
                                                    <span>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11.2051 7.61523L7 11.8203L2.79494 7.61523L3.56836 6.84181L6.45312 9.72658V0H7.54688V9.72658L10.4316 6.84181L11.2051 7.61523ZM14 12.9062H0V14H14V12.9062Z"
                                                    fill="white"/>
                                            </svg>
                                        </span>
                                                </button>
                                            </div>
                                            <div className='range__wrapper'>
                                                <DatePicker startDate={startDate} endDate={endDate}/>
                                            </div>
                                        </div>
                                        <div className="table__wrapper">
                                            <table className="table table-sortable table-stat table-borderless">
                                                <thead>
                                                <tr className="table-header">
                                                    <th className="fcolumn"/>
                                                    <th>{getTranslatedWord(path,'Баннер')}</th>
                                                    <th>{getTranslatedWord(path,'Статус')}</th>
                                                    <th>{getTranslatedWord(path,'Количество показов')}</th>
                                                    <th>{getTranslatedWord(path,'Количество кликов')}</th>
                                                    <th className="lcolumn"/>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    offers.map(function (offer, i) {
                                                        return <tr key={i}>
                                                            <td className="fcolumn"/>
                                                            <td>{offer?.name}</td>
                                                            <td>{offer?.status}</td>
                                                            <td>{offer?.countClick}</td>
                                                            <td>{offer?.countView}</td>
                                                            <td className="lcolumn"/>
                                                        </tr>
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='pagination__block'>
                                        <Select2 onChangeCallBack={changeNumberShowOffers}/>
                                        <Pagination
                                            onPageChange={handlePageClick}
                                            pageCount={Math.ceil(total / NumberShowOffers)}
                                        />
                                    </div>
                                </div>
                            </Container>
                        </main>
                    </>
                    : ''
            }

            <Footer/>
            <Modal show={show} onHide={modalClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h3>Экспорт данных</h3>
                    <div>
                        <p>Не закрывайте окно — создаем файл для скачивания</p>
                        <div className='progress__block'>
                            <ProgressBar now={now} label={`${now}%`} visuallyHidden></ProgressBar>
                            <span>{`${now}%`}</span>
                        </div>

                    </div>
                    <div>
                        <p>Файл для скачивания готов!</p>
                        <a href="##" className="primary-button">Скачать файл</a>
                    </div>
                </Modal.Body>

            </Modal>

        </>
    )
}

export default compose(
    withAuthRedirect
)(BannersPage)