// PAGE MAIN
export const GET_HOME_OFFER = 'GET_HOME_OFFER';
export const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING';
export const SET_EXPORT_POSTBACK_LINK = 'SET_EXPORT_POSTBACK_LINK'
export const SET_PROGRESS_MAIN = 'SET_PROGRESS_MAIN'
export const RESPONSE_SUPPORT_SUCCESS = 'RESPONSE_SUPPORT_SUCCESS'
export const CLEAR_EXPORT_LINK_AND_PROGRESS = 'CLEAR_EXPORT_LINK_AND_PROGRESS'
export const IS_PRELOADER_ACTIVE = 'IS_PRELOADER_ACTIVE'
export const ARE_OFFERS_LOADED = 'ARE_OFFERS_LOADED'

// PAGE WEBS
export const GET_WEBS_OFFER = 'GET_WEBS_OFFER';
export const SET_WEBS = 'SET_WEBS'
export const SET_EXPORT_WEBS_LINK = 'SET_EXPORT_WEBS_LINK'
export const SET_PROGRESS_WEBS = 'SET_PROGRESS_WEBS'
export const SET_IS_LOADED = 'SET_IS_LOADED'
export const IS_WEB_BLOCKED = 'IS_WEB_BLOCKED'
export const SET_WEBS_TOTAL = 'SET_WEBS_TOTAL'

// PAGE BANNERS
export const GET_BANNERS_OFFER = 'GET_BANNERS_OFFER';

// PAGE OFFERS
export const GET_DOCUMENTS_OFFER = 'GET_DOCUMENTS_OFFER';

// PAGE HISTORY
export const GET_HISTORY_OFFER = 'GET_HISTORY_OFFER';

// AUTH REDUCER
export const SET_AUTH_USER_REDUCER = 'SET_AUTH_USER_REDUCER'
export const IS_AUTH_USER_REDUCER = 'IS_AUTH_USER_REDUCER'
export const SET_ME_DATA_REDUCER = 'SET_ME_DATA_REDUCER'


// DATA
export const SET_DATA_COURSES = 'SET_DATA_COURSES'
export const SET_DATA_NOTIFICATIONS = 'SET_DATA_NOTIFICATIONS'
export const DELETE_DATA_NOTIFICATIONS = 'DELETE_DATA_NOTIFICATIONS'
export const SET_LANG = 'SET_LANG'
export const GET_TRANSLATION = 'GET_TRANSLATION'
export const SET_TRAFFIC_BY_TIME = 'SET_TRAFFIC_BY_TIME'
export const SET_PICKED_DATE = 'SET_PICKED_DATE'
export const GET_OFFERS_DATA = 'GET_OFFERS_DATA'
export const GET_OFFERS_PDF_DATA = 'GET_OFFERS_PDF_DATA'
export const CLEAR_OFFERS = 'CLEAR_OFFERS'

// TRANSLATION PATH
export const GENERAL = 'app/offer/general'
export const HOMEPAGE = 'app/offer/homePage'
export const BANNERS = 'app/offer/banners'
export const WEBS = 'app/offer/webs'
export const DOCUMENTS = 'app/offer/documents'
export const HISTORY = 'app/offer/history'




