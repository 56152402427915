import React, {useEffect, useState} from 'react'
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory} from "react-router-dom";
import {useParams} from "react-router-dom";

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarSubmenu from "../../components/Sidebar/SidebarSubmenu/SidebarSubmenu";
import Footer from "../../components/Footer/Footer";
import DatePicker from "../../components/DateRangePicker/DatePicker";
import Pagination from "../../components/Pagination/Pagination";
import Select2 from "../../components/Select/Select";

import classes from './HistoryPage.module.css'
import {compose} from "redux";
import {withAuthRedirect} from "../../hoc/withAuthRedirect";

const HistoryPage = () => {
    let params = useParams();
    let page = params.page;
    const now = 30;
    const history = useHistory();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [ActiveSidebar, setActiveSidebar] = useState(false);
    const [NumberShowOffers, setNumberShowOffers] = useState(50);

    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);

    const changeSidebar = () => {
        setActiveSidebar(!ActiveSidebar)
    };

    const changeNumberShowOffers = (num) => {
        setNumberShowOffers(Number(num))
    };

    const handlePageClick = (data) => {
        let page = Number(data.selected) + 1;
        const target = `/history/${page}/`;
        history.push(target)
    };

    const offers = useSelector(state => state.history.offers);
    const total = useSelector(state => state.history.total);
    const startDate = useSelector(state => state.data.date.dateStart)
    const endDate = useSelector(state => state.data.date.dateEnd)

    useEffect(() => {
        dispatch(getOffersForHistory({count: NumberShowOffers}))
    }, [NumberShowOffers, page]);

    return (
        <>
            <Header/>
            <Sidebar ActiveSidebar={ActiveSidebar} changeSidebar={changeSidebar}/>
            <SidebarSubmenu ActiveSidebar={ActiveSidebar} changeSidebar={changeSidebar}/>
            {

                offers.length > 0 ?
                    <>
                        <Redirect to="/history/1"/>
                        <main className={`main ${ActiveSidebar ? 'toggled' : ""}`}>
                            <Container fluid='md'>
                                <div className={classes.history__wrapper}>
                                    <h2>История изменения ставок</h2>
                                    <div className="main__content">
                                        <div className="data__config d-md-flex justify-content-between">
                                            <div className="data__export">
                                                <button className="primary-button" onClick={modalShow}>Экспорт
                                                    данных
                                                    <span>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11.2051 7.61523L7 11.8203L2.79494 7.61523L3.56836 6.84181L6.45312 9.72658V0H7.54688V9.72658L10.4316 6.84181L11.2051 7.61523ZM14 12.9062H0V14H14V12.9062Z"
                                                    fill="white"/>
                                            </svg>
                                        </span>
                                                </button>
                                            </div>
                                            <div className='range__wrapper'>
                                                <DatePicker startDate={startDate} endDate={endDate}/>
                                            </div>
                                        </div>
                                        <div className={classes.webs__filter}>
                                            <select name="choose__web" id="choose__web" className="select2">
                                                <option value="" disabled>Выберите веба</option>
                                                <option value="">aff_id</option>
                                                <option value="">Click ID</option>
                                            </select>
                                        </div>
                                        <div className={classes.selected__options}>
                                            <div className={classes.selected__block}>
                                                <div>
                                                    <div className={classes.selected__offer}>Вебы</div>
                                                    <div className='d-flex flex-wrap'>
                                                        <div className={classes.selected__option_item}>
                                                            aff_id
                                                            <span>
                                                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14.9868 2L13.4882 0.5L7.49342 6.5L1.49868 0.5L0 2L5.99474 8L0 14L1.49868 15.5L7.49342 9.5L13.4882 15.5L14.9868 14L8.99211 8L14.9868 2Z"
                                                            fill="#575757"/>
                                                    </svg>
                                                </span>
                                                        </div>
                                                        <div className={classes.selected__option_item}>
                                                            aff_id
                                                            <span>
                                                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14.9868 2L13.4882 0.5L7.49342 6.5L1.49868 0.5L0 2L5.99474 8L0 14L1.49868 15.5L7.49342 9.5L13.4882 15.5L14.9868 14L8.99211 8L14.9868 2Z"
                                                            fill="#575757"/>
                                                    </svg>
                                                </span>
                                                        </div>
                                                        <div className={classes.selected__option_item}>
                                                            aff_id
                                                            <span>
                                                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14.9868 2L13.4882 0.5L7.49342 6.5L1.49868 0.5L0 2L5.99474 8L0 14L1.49868 15.5L7.49342 9.5L13.4882 15.5L14.9868 14L8.99211 8L14.9868 2Z"
                                                            fill="#575757"/>
                                                    </svg>
                                                </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.buttons__block} d-flex`}>
                                            <a href="##" className="primary-button me-4">Применить параметры</a>
                                            <a href="##" className="reset-button">Сбросить фильтр</a>
                                        </div>
                                        <div className="table__wrapper">
                                            <table className="table table-sortable table-stat table-borderless">
                                                <thead>
                                                <tr className="table-header">
                                                    <th className="fcolumn"></th>
                                                    <th>Дата</th>
                                                    <th>Веб</th>
                                                    <th>Ставка</th>
                                                    <th className="lcolumn"></th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                {
                                                    offers.map(function (offer, i) {
                                                        return <tr key={i}>
                                                            <td className="fcolumn"></td>
                                                            <td>{offer?.date}</td>
                                                            <td>{offer?.userName}
                                                                <span>
                                                                <i className="info-icon" data-bs-toggle="tooltip"
                                                                   data-bs-title="Podskazka"></i>
                                                            </span>
                                                            </td>
                                                            <td>{offer?.bid}</td>
                                                            <td className="lcolumn"></td>
                                                        </tr>

                                                    })

                                                }

                                                </tbody>

                                            </table>

                                        </div>
                                    </div>
                                    <div className='pagination__block'>
                                        <Select2 onChangeCallBack={changeNumberShowOffers}/>
                                        <Pagination
                                            onPageChange={handlePageClick}
                                            pageCount={Math.ceil(total / NumberShowOffers)}
                                        />
                                    </div>
                                </div>
                            </Container>
                        </main>
                    </>
                    :
                    ''
            }

            <Footer/>

            <Modal show={show} onHide={modalClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h3>Экспорт данных</h3>
                    <div>
                        <p>Не закрывайте окно — создаем файл для скачивания</p>
                        <div className='progress__block'>
                            <ProgressBar now={now} label={`${now}%`} visuallyHidden></ProgressBar>
                            <span>{`${now}%`}</span>
                        </div>

                    </div>
                    <div>
                        <p>Файл для скачивания готов!</p>
                        <a href="##" className="primary-button">Скачать файл</a>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default compose(
    withAuthRedirect
)(HistoryPage)