import React, {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Modal from 'react-bootstrap/Modal';
import {useParams} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import pdlLogo from '../../images/pdl-big-logo-by-trionika.jpg'

import {
    exportPostbacksThunk,
    getTableOffers,
    getTrafficByTimeGraph,
    getTranslatedWord,
    getTranslation, queryOffersDataThunk, uploadOffersThunk,
} from "../../store/actions";

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarSubmenu from "../../components/Sidebar/SidebarSubmenu/SidebarSubmenu";
import Footer from "../../components/Footer/Footer";
import Chart from "../../components/ChartBlock/Chart/Chart";
import TableBlock from "../../components/TableBlock/TableBlock";
import DatePicker from "../../components/DateRangePicker/DatePicker";
import Pagination from "../../components/Pagination/Pagination";
import Select2 from "../../components/Select/Select";

import classes from './HomePage.module.css';
import {compose} from "redux";
import {withAuthRedirect} from "../../hoc/withAuthRedirect";
import Preloader from "../../components/common/Preloader/Preloader";
import {ARE_OFFERS_LOADED, HOMEPAGE} from "../../store/types";
import {Button} from "react-bootstrap";
import {generatePdf} from "../../utils/generatePdf/generatePdf";


const HomePage = () => {

    const path = HOMEPAGE;
    const dispatch = useDispatch();
    const [NumberShowOffers, setNumberShowOffers] = useState(50);
    const [paginateNum, setPaginateNum] = useState(1);
    const [ActiveSidebar, setActiveSidebar] = useState(false);
    const [chartTable, setChartTable] = useState(false);
    const [filterSelected, setFilterSelected] = useState({});
    const [extraFilter, setExtraFilter] = useState(false);
    const [show, setShow] = useState(false);
    const [showPdfModal, setShowPdfModal] = useState(false)
    const [page, setPage] = useState(1)
    const [disabled, setDisabled] = useState(false)

    const {pid} = useParams();
    const {offers, exportLink, isPreloaderActive, areOffersLoaded, maxPage, progress} = useSelector(({main}) => ({
        offers: main.offers,
        exportLink: main.link,
        isPreloaderActive: main.isPreloaderActive,
        areOffersLoaded: main.areOffersLoaded,
        maxPage: main.max,
        progress: main.progress,
    }));

    const {isTranslated, lang, translation, traffic, startDate, endDate, leadsSum, offersData, avarageCR, avarageECPC, offersPdfData} = useSelector(({data}) => ({
        isTranslated: data.isTranslated,
        lang: data.lang,
        translation: data.translation,
        traffic: data.traffic,
        startDate: data.date.dateStart,
        endDate: data.date.dateEnd,
        leadsSum: data.leadsSum,
        offersData: data.offers,
        avarageCR: data.avarageCR,
        avarageECPC: data.avarageECPC,
        offersPdfData: data.offersPdf
    }))

    const {me, id, currency} = useSelector(({auth}) => ({
        me: auth.me,
        id: auth.me.offer.id,
        currency: auth.me.baseCurrency
    }))

    const preloader = {
        company: {
            position: 'absolute', right: 0
        },
        general: {
            position: 'absolute', top: '50%', left: '50%'
        },
        offers: {
            position: 'relative',
            left: '50%',
            width: '100%',
            transform: 'translateX(-50%)',
            display: 'flex',
            justifyContent: 'center',
            top: '15px'
        }

    }

    let selectOptionsStatus = [{value: 'approved', label: 'approved'}, {
        value: 'pending',
        label: 'pending'
    }, {value: 'rejected', label: 'rejected'},];


    const exportPdf = function () {
        setDisabled(true)
        generatePdf().then(function (res) {
            res.save(`PDLP_${startDate}-${endDate}`);
            setDisabled(false)
        })
    }


    const changeFilterSelect = (event, key) => {
        if (event.value !== undefined || event.target.value !== '') {
            setFilterSelected({
                ...filterSelected,
                [event.value ?? event.target.value]: {name: event.label ?? event.target.value, filter: key}
            });
        }

    };

    const handleKeyDown = (event, key) => {
        if (event.key === 'Enter') {

            changeFilterSelect(event, key)
            event.target.value = ''

        }
    }

    const deleteOffer = (value) => {
        let filterAllSelected = {...filterSelected};
        delete filterAllSelected[value];
        setFilterSelected(filterAllSelected)
    };

    const changeExtraFilter = () => {
        setExtraFilter(!extraFilter)
    };

    const changeSidebar = () => {
        setActiveSidebar(!ActiveSidebar);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClosePdfModal = () => setShowPdfModal(false)
    const handleShowPdfModal = () => setShowPdfModal(true)

    const chartTableClose = () => setChartTable(false);
    const chartTableOpen = () => setChartTable(true);

    const changeNumberShowOffers = (num) => {
        setNumberShowOffers(Number(num));
    };

    const handlePageClick = (data) => {
        setPaginateNum(Number(data.selected) + 1);
    };

    const exportStatistics = () => {
        if (progress === 0 && exportLink === '') {
            dispatch(exportPostbacksThunk({
                page: paginateNum,
                perPage: NumberShowOffers,
                'query[date_from]': startDate,
                'query[date_to]': endDate
            }))
        }
    }
    const applyParams = () => {
        if (paginateNum === 1) {
            dispatch(getTableOffers(startDate, endDate, 1, NumberShowOffers, filterSelected))

        } else {
            setPaginateNum(1)
        }
    }

    const clearFilter = () => {
        setFilterSelected({})
    }

    const getOffersPdf = () => {
        dispatch(queryOffersDataThunk(1, 100, pid, startDate, endDate, currency, true))
    }

    useEffect(() => {
        areOffersLoaded && dispatch(queryOffersDataThunk(page, 6, pid, startDate, endDate, currency, false))
    }, [areOffersLoaded, page, pid])

    useEffect(() => {
        if (!areOffersLoaded) {
            setPage(1)
            uploadOffersThunk({
                'dateFrom': `${startDate} 00:00:00`,
                'dateTo': `${endDate} 23:59:59`,
            })
                .then(() => {
                    dispatch({type: ARE_OFFERS_LOADED, payload: true})
                })
        }
    }, [startDate, endDate, areOffersLoaded])

    useEffect(() => {
        dispatch(getTranslation(path, lang))
    }, [lang])

    useEffect(() => {
        if (id !== '') {
            dispatch(getTrafficByTimeGraph({
                'query[date_from]': startDate,
                'query[date_to]': endDate,
                'query[offers][]': id,
                'currency': currency
            }, pid))
        }
    }, [startDate, endDate, id, pid])


    useEffect(() => {
        dispatch(getTableOffers(startDate, endDate, paginateNum, NumberShowOffers, filterSelected))
    }, [startDate, endDate, paginateNum, NumberShowOffers])

    return (
        <>
            <Header/>
            <Sidebar ActiveSidebar={ActiveSidebar} changeSidebar={changeSidebar}/>
            <SidebarSubmenu ActiveSidebar={ActiveSidebar} changeSidebar={changeSidebar}/>


            <main className={`main ${ActiveSidebar ? 'toggled' : ""}`}>
                {isTranslated &&
                Object.keys(translation).includes(path) &&
                Object.keys(traffic).length !== 0 ?
                    <Container fluid='md'>
                        <div className={classes.statistic__wrapper}>
                            <h2>{getTranslatedWord(path, 'Статистика')} {pid ? '(' + getTranslatedWord(path, 'Веб') + ': ' + pid + ')' : ''}
                            </h2>
                            <div className={`${classes.total__stats} main__content`}>
                                <div className={`${classes.total__stats_block} d-md-flex justify-content-between`}>
                                    <h3 className={`${classes.total__stats_title} d-flex  align-items-center`}>
                                        {getTranslatedWord(path, 'Общая статистика по офферам')}
                                    </h3>
                                    {me.offer !== undefined && me.offer.name !== '' ? <div
                                        className={`${classes.company__info} justify-content-between d-flex align-items-center`}>

                                        <div className={classes.company__name}>{me?.offer?.name}</div>
                                        <div className={classes.company__logo}>
                                            <img src={me?.offer?.image} alt="offer-logo"/>
                                        </div>
                                    </div> : <Preloader style={preloader.company}/>}


                                </div>
                                <div className="data__config d-md-flex justify-content-between">
                                    <div className="data__export">
                                        {Object.keys(offers).length !== 0 && chartTable ?
                                            <button className='primary-button'
                                                    onClick={() => {
                                                        handleShow();
                                                        exportStatistics()
                                                    }}>{getTranslatedWord(path, 'Экспорт данных')}
                                                <span>
                                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M11.2051 7.61523L7 11.8203L2.79494 7.61523L3.56836 6.84181L6.45312 9.72658V0H7.54688V9.72658L10.4316 6.84181L11.2051 7.61523ZM14 12.9062H0V14H14V12.9062Z"
                                                        fill="white"/>
                                                    </svg>
                                                </span>
                                            </button>
                                            : ''
                                        }

                                        {(leadsSum !== 0 || Object.keys(offersData).length !== 0) && !chartTable && areOffersLoaded ?
                                            <button className='primary-button'
                                                    onClick={() => {
                                                        handleShowPdfModal();
                                                        getOffersPdf()
                                                    }}>{getTranslatedWord(path, 'Экспорт данных')}
                                                <span>
                                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M11.2051 7.61523L7 11.8203L2.79494 7.61523L3.56836 6.84181L6.45312 9.72658V0H7.54688V9.72658L10.4316 6.84181L11.2051 7.61523ZM14 12.9062H0V14H14V12.9062Z"
                                                        fill="white"/>
                                                    </svg>
                                                </span>
                                            </button>
                                            : ''
                                        }

                                    </div>
                                    <div className={`range__wrapper`}>
                                        <DatePicker disabled={areOffersLoaded} page={page} setPage={setPage}
                                                    startDate={startDate}
                                                    endDate={endDate}/>
                                    </div>

                                </div>
                                <div className={`${classes.data__types} data_types d-md-flex`}>
                                    <button onClick={() => {
                                        chartTableClose()
                                    }}
                                            className={`${classes.params} ${!chartTable ? classes.active : ''} d-flex align-items-center justify-content-center`}>
                                        {getTranslatedWord(path, 'График')}
                                    </button>
                                    <button onClick={() => {
                                        chartTableOpen()
                                    }}
                                            className={`${classes.params} ${chartTable ? classes.active : ''} d-flex align-items-center justify-content-center`}>
                                        {getTranslatedWord(path, 'Таблица')}
                                    </button>
                                </div>


                                {chartTable ? <>
                                        <div className={classes.extra__filter}>
                                                <span onClick={() => {
                                                    changeExtraFilter()
                                                }}
                                                      className={`${classes.extra__filter_title} ${extraFilter ? classes.toggled : ''}`}>
                                            {getTranslatedWord(path, 'Дополнительные параметры фильтра')}</span>
                                            {extraFilter ?
                                                <>
                                                    <div className='d-flex mt-2 mb-4 extra__filter--block flex-wrap'>
                                                        <Select
                                                            onChange={event => changeFilterSelect(event, 'status')}
                                                            menuPlacement='auto'
                                                            classNamePrefix='filter'
                                                            options={selectOptionsStatus}/>

                                                        <input type="number"
                                                               placeholder='Partner_id'
                                                               onKeyDown={event => {
                                                                   handleKeyDown(event, 'parentId')
                                                               }}
                                                               onBlur={event => {
                                                                   changeFilterSelect(event, 'parentId');
                                                                   event.target.value = ''
                                                               }}/>
                                                        <input type="text"
                                                               placeholder='Transaction_id'
                                                               onKeyDown={event => {
                                                                   handleKeyDown(event, 'transactionId')
                                                               }}
                                                               onBlur={event => {
                                                                   changeFilterSelect(event, 'transactionId');
                                                                   event.target.value = ''
                                                               }}/>
                                                    </div>
                                                    <div className={classes.selected__options}>

                                                        {Object.keys(filterSelected).length !== 0 ?
                                                            <p>{getTranslatedWord(path, 'Выбранные параметры:')}</p> : ''}

                                                        <div className={classes.selected__block}>
                                                            <div>


                                                                <div className='d-flex flex-wrap'>

                                                                    {Object.keys(filterSelected).length > 0 ? Object.entries(filterSelected).map((el, i) => {
                                                                        return <div key={i}
                                                                                    className={classes.selected__option_item}>
                                                                            {el[1].name}
                                                                            <span onClick={() => deleteOffer(el[0])}>
                                                                            <svg width="15" height="16"
                                                                                 viewBox="0 0 15 16" fill="none"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M14.9868 2L13.4882 0.5L7.49342 6.5L1.49868 0.5L0 2L5.99474 8L0 14L1.49868 15.5L7.49342 9.5L13.4882 15.5L14.9868 14L8.99211 8L14.9868 2Z"
                                                                                    fill="#575757"/>
                                                                            </svg>
                                                                        </span>
                                                                        </div>
                                                                    }) : ''}


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`${classes.buttons__block} d-flex`}>
                                                        <button onClick={applyParams}
                                                                className='primary-button me-4'>{getTranslatedWord(path, 'Применить параметры')}</button>
                                                        <button onClick={clearFilter}
                                                                className='reset-button'>{getTranslatedWord(path, 'Сбросить фильтр')}</button>
                                                    </div>
                                                </>
                                                : ''}

                                        </div>


                                        <TableBlock offers={offers}/>
                                    </>

                                    : <>
                                        <div className='chart-main-container'>
                                            {Object.keys(traffic).length > 0 ?
                                                <Chart options={traffic.options} data={traffic.data}/> : ''}

                                        </div>
                                        <div className={`${classes.summary} summary`}>
                                            <p>{getTranslatedWord(path, 'Колво уников')} за
                                                ({startDate} - {endDate}): {leadsSum}</p>
                                            <p>{getTranslatedWord(path, 'Средний')} CR
                                                avg: {!isNaN(avarageCR) ? avarageCR + ' %' : 'no data'} </p>
                                            <p>{getTranslatedWord(path, 'Средний')} EPC
                                                avg: {!isNaN(avarageECPC) ? avarageECPC + ' ' + currency : 'no data'} </p>
                                        </div>
                                        <div className={classes.plug__block}/>
                                    </>


                                }


                            </div>

                            {chartTable ?

                                Object.keys(offers).length !== 0 ?
                                    <div className='pagination__block'>
                                        <Select2 onChangeCallBack={changeNumberShowOffers}/>
                                        <Pagination
                                            paginateNum={paginateNum}
                                            onPageChange={handlePageClick}
                                            pageCount={maxPage}
                                        />
                                    </div> : ''

                                : offersData.length === 0 ? ''
                                    :

                                    <div className={classes.offers__stats}>
                                        <h3>{getTranslatedWord(path, 'Общая статистика по другим офферам')}</h3>
                                        <InfiniteScroll
                                            dataLength={offersData.length}
                                            next={() => setPage(page + 1)}
                                            hasMore={true}
                                            className={`${classes.offer__stats_block} row`}
                                        >
                                            {offersData.map((offer, i) => {
                                                return <div key={i}
                                                            className={`${classes.offer__stats_item} test-${i} col-12 col-md-6`}>
                                                    <div className={classes.offer__stats_wrap}>
                                                        <div
                                                            className={`${classes.offer__stats_title} d-flex justify-content-between align-items-center`}>
                                                            <div className={classes.offer__stats_name}>
                                                                {offer?.name}
                                                            </div>
                                                            <div className={classes.offer__stats_logo}>
                                                                <img src={offer.image} alt={offer?.name}/>
                                                            </div>
                                                        </div>
                                                        <div className='chart-offers-container'>

                                                            <Chart data={offer.chart.data}
                                                                   options={offer.chart.options}/>
                                                        </div>
                                                        <div className={classes.offer__stats_summary}>
                                                            <p>{getTranslatedWord(path, 'Средний')} CR
                                                                avg: {!isNaN(offer.cr) ? offer.cr + ' %' : 'no data'}</p>
                                                            <p>{getTranslatedWord(path, 'Средний')} EPC
                                                                avg: {!isNaN(offer.ecpc) ? offer.ecpc + ' ' + currency : 'no data'} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            })

                                            }
                                        </InfiniteScroll>
                                    </div>
                            }

                            {chartTable ? '' : (isPreloaderActive ? <Preloader style={preloader.offers}/> : '')}


                        </div>
                    </Container>
                    : <Preloader style={preloader.general}/>

                }
            </main>


            <Footer/>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <h3>{getTranslatedWord(path, 'Экспорт данных')}</h3>

                    {exportLink === '' ?
                        <>
                            <p>{getTranslatedWord(path, 'Не закрывайте окно — создаем файл для скачивания')}</p>
                            <div className='progress__block'>
                                <ProgressBar now={progress} label={`${progress}%`} visuallyHidden/>
                                <span>{`${progress}%`}</span>
                            </div>
                        </>
                        :
                        <div>
                            <p>{getTranslatedWord(path, 'Файл для скачивания готов!')}</p>
                            <a href={exportLink} onClick={() => setShow(false)}
                               className="primary-button">{getTranslatedWord(path, 'Скачать файл')}</a>
                        </div>

                    }

                </Modal.Body>

            </Modal>

            <Modal dialogClassName='modal-90w' show={showPdfModal} onHide={handleClosePdfModal}
                   aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton/>
                <Modal.Body>
                    <div className='pdf__wrapper'>
                        <div className='pdf__title d-flex justify-content-between align-items-center'>
                            <h2>{getTranslatedWord(path, 'Общая статистика по офферам')}</h2>
                            <img className='pdl__logo' src={pdlLogo} alt="pdlLogo"/>
                        </div>
                        <div className='d-flex flex-column'>
                            <div className='pdf__date'>{getTranslatedWord(path, 'Период')}: {startDate} по {endDate}</div>
                            <Button disabled={disabled} onClick={() => exportPdf()}
                                    className="primary-button">{getTranslatedWord(path, 'Скачать файл')}
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.2051 7.61523L7 11.8203L2.79494 7.61523L3.56836 6.84181L6.45312 9.72658V0H7.54688V9.72658L10.4316 6.84181L11.2051 7.61523ZM14 12.9062H0V14H14V12.9062Z"
                                        fill="white"/>
                                </svg>
                            </Button>
                        </div>
                        <div className='chart-main-container--wrapper'>
                            <div className='chart-main-container pdf_chart' data-name={me.offer.name}>
                                {Object.keys(traffic).length > 0 ?
                                    <Chart className='canvas__main' options={traffic.options} data={traffic.data}/> : ''}
                            </div>
                            <div className='stats__summary-pdf'>
                                <p>{getTranslatedWord(path, 'Колво уников')} за
                                    ({startDate} - {endDate}): {leadsSum}</p>
                                <p>{getTranslatedWord(path, 'Средний')} CR
                                    avg: {!isNaN(avarageCR) ? avarageCR + ' %' : 'no data'} </p>
                                <p>{getTranslatedWord(path, 'Средний')} EPC
                                    avg: {!isNaN(avarageECPC) ? avarageECPC + ' ' + currency : 'no data'} </p>
                            </div>
                        </div>


                        <div className='chart__offers--pdf'>
                            {offersPdfData.map((offer, i) => {
                                return <div key={i} data-name={offer?.name}
                                            className={`${classes.offer__stats_item} chart__offers--item col-6`}>
                                    <div className='offer__stats__wrap--pdf'>
                                        <div
                                            className={`${classes.offer__stats_title} d-flex justify-content-between align-items-center`}>
                                            <div className='offers__name--pdf'>
                                                {offer?.name}
                                            </div>
                                            <div className='offers__logo--pdf'>
                                                <img src={offer.image} alt={offer?.name}/>
                                            </div>
                                        </div>
                                        <div className='chart-offers-container'>
                                            <Chart data={offer.chart.data}
                                                   options={offer.chart.options}/>
                                        </div>
                                        <div className='stats__summary-pdf'>
                                            <p>{getTranslatedWord(path, 'Средний')} CR
                                                avg: {!isNaN(offer.cr) ? offer.cr + ' %' : 'no data'}</p>
                                            <p>{getTranslatedWord(path, 'Средний')} EPC
                                                avg: {!isNaN(offer.ecpc) ? offer.ecpc + ' ' + currency : 'no data'} </p>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

        </>
    )
}

export default compose(withAuthRedirect)(HomePage)


