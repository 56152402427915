import React, {useState, useEffect, useRef} from 'react'
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import {useDispatch, useSelector} from "react-redux";
import {
    exportWebsThunk,
    getTranslatedWord,
    getTranslation,
    queryWebs,
    uploadWebsThunk
} from "../../store/actions";
import {Link, Redirect, useHistory} from "react-router-dom";
import {useParams} from "react-router-dom";

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarSubmenu from "../../components/Sidebar/SidebarSubmenu/SidebarSubmenu";
import Footer from "../../components/Footer/Footer";
import DatePicker from "../../components/DateRangePicker/DatePicker";
import Pagination from "../../components/Pagination/Pagination";
import Select2 from "../../components/Select/Select";
import ModalBlockWeb from "../../components/common/Modals/ModalBlockWeb/ModalBlockWeb";

import classes from './WebsPage.module.css'
import {compose} from "redux";
import {withAuthRedirect} from "../../hoc/withAuthRedirect";
import {
    ARE_OFFERS_LOADED,
    CLEAR_OFFERS, IS_PRELOADER_ACTIVE,
    IS_WEB_BLOCKED,
    SET_IS_LOADED,
    TOGGLE_IS_FETCHING,
    WEBS
} from "../../store/types";
import moment from 'moment';
import Preloader from "../../components/common/Preloader/Preloader";
import noSymbol from './../../images/no-symbol.png'
import search from './../../images/search.svg'
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Select from 'react-select';


const WebsPage = () => {

    let params = useParams();
    let page = params.page;
    let initialValueClickDate = localStorage.getItem('clickDate') ? +localStorage.getItem('clickDate') : 0
    let clickDateRef = useRef(initialValueClickDate)
    const path = WEBS;
    const history = useHistory();
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [expand, setExpand] = useState(false)
    const [ActiveSidebar, setActiveSidebar] = useState(false);
    const [NumberShowOffers, setNumberShowOffers] = useState(50);
    const [paginateNum, setPaginateNum] = useState(1);
    const [blockedId, setBlockedId] = useState(null)
    const [sortTable, setSortTable] = useState({name: '', dir: 'desc'})
    const [selectedGoalId, setSelectedGoal] = useState('All');

    const {webs, exportLink, progress, isWebBlocked, isLoaded, maxPage, websTotal} = useSelector(({webs}) => ({
        webs: webs.webs,
        exportLink: webs.link,
        progress: webs.progress,
        isWebBlocked: webs.isWebBlocked,
        isLoaded: webs.isLoaded,
        maxPage: webs.max,
        websTotal: webs.websTotal
    }))

    const {lang, startDate, endDate, translation} = useSelector(({data}) => ({
        lang: data.lang,
        startDate: data.date.dateStart,
        endDate: data.date.dateEnd,
        translation: data.translation,
    }))

    const {currency, leadTypes = [] } = useSelector(({auth}) => ({
        currency: auth.me.baseCurrency,
        leadTypes: auth.me.offer.leadTypes
    }))

    const optionsLeadTypes = leadTypes.map(type => ({ value: type.id, label: type.name }));
    optionsLeadTypes.unshift({ value: 'All', label: 'All goals' });

    const isFetching = useSelector(({main}) => main.isFetching)


    const preloader = {
        webs: {
            position: 'absolute', top: '5px', left: '50%', transform: 'translateX(-50%)'
        }

    }

    const openModal = () => setExpand(true)
    const closeModal = () => setExpand(false)

    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);

    const changeSidebar = () => {
        setActiveSidebar(!ActiveSidebar)
    };

    const clearOffers = () => {
        dispatch({type: CLEAR_OFFERS})
        dispatch({
            type: IS_PRELOADER_ACTIVE,
            payload: true
        })
        dispatch({type: ARE_OFFERS_LOADED, payload: false})
    }

    const changeSWitch = async () => {
        clickDateRef.current = clickDateRef.current === 1 ? 0 : 1
        localStorage.setItem('clickDate', clickDateRef.current.toString())
        dispatch({
            type: TOGGLE_IS_FETCHING,
            isFetching: true
        })
        const query = {
            dateTo: moment.utc(`${endDate} 23:59:59`).subtract(1, 'hours').unix(),
            dateFrom: moment.utc(`${startDate} 00:00:00`).subtract(1, 'hours').unix(),
            clickDate: clickDateRef.current,
            currency: currency
        }
    
        if (selectedGoalId && selectedGoalId !== 'All') {
            query.leadTypes = [selectedGoalId];
        }

        await uploadWebsThunk(query)
        dispatch(queryWebs(paginateNum, NumberShowOffers, sortTable))
        dispatch({
            type: TOGGLE_IS_FETCHING,
            isFetching: false
        })
    };

    const changeNumberShowOffers = async (num) => {
        dispatch({
            type: TOGGLE_IS_FETCHING,
            isFetching: true
        })
        const query = {
            dateTo: moment.utc(`${endDate} 23:59:59`).subtract(1, 'hours').unix(),
            dateFrom: moment.utc(`${startDate} 00:00:00`).subtract(1, 'hours').unix(),
            clickDate: clickDateRef.current,
            currency: currency
        }
    
        if (selectedGoalId && selectedGoalId !== 'All') {
            query.leadTypes = [selectedGoalId];
        }

        await uploadWebsThunk(query)
        dispatch({type: SET_IS_LOADED, payload: true})
        dispatch({
            type: TOGGLE_IS_FETCHING,
            isFetching: false
        })
        setNumberShowOffers(Number(num))
    };

    const handlePageClick = async (data) => {
        dispatch({
            type: TOGGLE_IS_FETCHING,
            isFetching: true
        })
        const query = {
            dateTo: moment.utc(`${endDate} 23:59:59`).subtract(1, 'hours').unix(),
            dateFrom: moment.utc(`${startDate} 00:00:00`).subtract(1, 'hours').unix(),
            clickDate: clickDateRef.current,
            currency: currency
        }
    
        if (selectedGoalId && selectedGoalId !== 'All') {
            query.leadTypes = [selectedGoalId];
        }

        await uploadWebsThunk(query)
        dispatch({type: SET_IS_LOADED, payload: true})
        dispatch({
            type: TOGGLE_IS_FETCHING,
            isFetching: false
        })
        setPaginateNum(Number(data.selected) + 1);
        page = paginateNum;
        const target = `/webs/${page}/`;
        history.push(target)
    };

    const handleLeadTypes = async (selectedOption) => {
        const selectedGoalId = selectedOption ? selectedOption.value : '';
        setSelectedGoal(selectedGoalId);

        dispatch({
            type: TOGGLE_IS_FETCHING,
            isFetching: true
        })
        const query = {
            dateTo: moment.utc(`${endDate} 23:59:59`).subtract(1, 'hours').unix(),
            dateFrom: moment.utc(`${startDate} 00:00:00`).subtract(1, 'hours').unix(),
            clickDate: clickDateRef.current,
            currency: currency
        }
    
        if (selectedGoalId && selectedGoalId !== 'All') {
            query.leadTypes = [selectedGoalId];
        }

        await uploadWebsThunk(query)
    
        dispatch(queryWebs(paginateNum, NumberShowOffers, sortTable))
        dispatch({
            type: TOGGLE_IS_FETCHING,
            isFetching: false
        })
    };

    const exportWebs = () => {
        if (progress === 0 && exportLink === '') {
            dispatch(exportWebsThunk({
                page: paginateNum,
                perPage: NumberShowOffers
            }))
        }
    }

    const blockWebFalse = () => {
        dispatch({
            type: IS_WEB_BLOCKED,
            payload: false
        })
    }

    const click = async (name, dir) => {
        if (sortTable.name !== undefined && sortTable.name === name) {
            if (sortTable.dir === 'desc') {
                dir = 'asc'
            } else {
                dir = 'desc'
            }
        }
        setSortTable({name, dir})

        dispatch({
            type: TOGGLE_IS_FETCHING,
            isFetching: true
        })
        const query = {
            dateTo: moment.utc(`${endDate} 23:59:59`).subtract(1, 'hours').unix(),
            dateFrom: moment.utc(`${startDate} 00:00:00`).subtract(1, 'hours').unix(),
            clickDate: clickDateRef.current,
            currency: currency
        }
    
        if (selectedGoalId && selectedGoalId !== 'All') {
            query.leadTypes = [selectedGoalId];
        }

        await uploadWebsThunk(query)
    
        dispatch(queryWebs(paginateNum, NumberShowOffers, sortTable))
        dispatch({
            type: TOGGLE_IS_FETCHING,
            isFetching: false
        })
    }


    useEffect(() => {
        dispatch(getTranslation(path, lang))
    }, [lang])

    useEffect(() => {
        isLoaded && dispatch(queryWebs(paginateNum, NumberShowOffers, sortTable))
    }, [isLoaded, paginateNum, NumberShowOffers])

    useEffect(async () => {
        if (!isLoaded && currency !== '' && currency !== undefined) {
            dispatch({
                type: TOGGLE_IS_FETCHING,
                isFetching: true
            })
            const query = {
                dateTo: moment.utc(`${endDate} 23:59:59`).subtract(1, 'hours').unix(),
                dateFrom: moment.utc(`${startDate} 00:00:00`).subtract(1, 'hours').unix(),
                clickDate: clickDateRef.current,
                currency: currency
            }
        
            if (selectedGoalId && selectedGoalId !== 'All') {
                query.leadTypes = [selectedGoalId];
            }
    
            await uploadWebsThunk(query)
            dispatch({type: SET_IS_LOADED, payload: true})
            dispatch({
                type: TOGGLE_IS_FETCHING,
                isFetching: false
            })
        }
    }, [startDate, endDate, isLoaded, currency, leadTypes])


    return (
        <>
            <Header/>
            <Sidebar ActiveSidebar={ActiveSidebar} changeSidebar={changeSidebar}/>
            <SidebarSubmenu ActiveSidebar={ActiveSidebar} changeSidebar={changeSidebar}/>
            {
                <>
                    <Redirect to="/webs/1"/>
                    <main className={`main ${ActiveSidebar ? 'toggled' : ""}`}>
                        <Container fluid='md'>
                            <div className={classes.webs__wrapper}>
                                <h2>{getTranslatedWord(path, 'Вебы')}</h2>
                                <div className='main__content'>
                                    <div className='data__config d-md-flex justify-content-between'>
                                        <div className='data__export'>
                                            {Object.keys(webs).length !== 0 &&
                                                <button className='primary-button'
                                                        onClick={() => {
                                                            modalShow();
                                                            exportWebs()
                                                        }
                                                        }>
                                                    {getTranslatedWord(path, 'Экспорт данных')}
                                                    <span>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11.2051 7.61523L7 11.8203L2.79494 7.61523L3.56836 6.84181L6.45312 9.72658V0H7.54688V9.72658L10.4316 6.84181L11.2051 7.61523ZM14 12.9062H0V14H14V12.9062Z"
                                                    fill="white"/>
                                            </svg>
                                        </span>
                                                </button>}

                                        </div>
                                        <div className='range__wrapper'>
                                            <DatePicker disabled={isLoaded} startDate={startDate} endDate={endDate}/>
                                        </div>
                                    </div>
                                    <div className='filters__wrapper'>
                                        <div className={`${classes.switch} d-flex align-items-center`}>
                                            <div onClick={() => {
                                                changeSWitch()
                                            }}
                                                className={`${classes.switch__btn} ${clickDateRef.current ? classes.toggled : ''}`}>

                                            </div>
                                            <div className={classes.switch__text}>
                                                {getTranslatedWord(path, 'Смотреть по дате клика')}
                                            </div>
                                        </div>
                                        <Select
                                            value={optionsLeadTypes.find(option => option.value === selectedGoalId)}
                                            onChange={handleLeadTypes}
                                            options={optionsLeadTypes}
                                            classNamePrefix='filter'
                                            isSearchable={false}
                                        />
                                    </div>
                                    <div className="table__wrapper">
                                        <table className="table table-sortable table-stat table-borderless">
                                            <thead>
                                            <tr className="table-header sort__table">
                                                <th className="fcolumn"/>
                                                <th>{getTranslatedWord(path, 'Веб')}</th>
                                                <th className='sort__col'
                                                    onClick={() => click('postbacks', 'desc')}>{getTranslatedWord(path, 'Конверсии')}</th>
                                                <th className='sort__col'
                                                    onClick={() => click('ecpc', 'desc')}>{getTranslatedWord(path, 'EPC')}</th>
                                                <th className='sort__col'
                                                    onClick={() => click('cr', 'desc')}>{getTranslatedWord(path, 'CR')}</th>
                                                <th className='sort__col'
                                                    onClick={() => click('clicks', 'desc')}>{getTranslatedWord(path, 'Уники')}</th>
                                                <th className='d-flex justify-content-center'
                                                    data-dir='desc'>{getTranslatedWord(path, 'Действие')}</th>
                                                <th className="lcolumn"/>
                                            </tr>
                                            <tr className="table-header sort__table">
                                                <th className="fcolumn"/>
                                                <th>{getTranslatedWord(path, 'Итого')}:</th>
                                                <th>{websTotal.leads}</th>
                                                <th>{websTotal.ecpc}</th>
                                                <th>{websTotal.cr}</th>
                                                <th>{websTotal.clicks}</th>
                                                <th className='d-flex justify-content-center'/>
                                                <th className="lcolumn"/>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {!isFetching ?
                                                webs.length > 0 ?
                                                    webs.map(function (web, i) {
                                                        return (
                                                            <tr key={i}>
                                                                <td className="fcolumn"/>
                                                                <td>
                                                                    <Link onClick={clearOffers} className='webId'
                                                                          to={`/${web.partner_id}`}>
                                                                        {web?.partner_id}
                                                                    </Link>
                                                                </td>
                                                                <td>{web?.postbacks}</td>
                                                                <td>{web?.ecpc}</td>
                                                                <td>{web?.cr}</td>
                                                                <td>{web?.clicks}</td>
                                                                <td className='d-flex justify-content-center'>

                                                                    <OverlayTrigger
                                                                        key={'top'}
                                                                        placement={'top'}
                                                                        overlay={
                                                                            <Tooltip>
                                                                                {getTranslatedWord(path, 'Заблокировать веба')}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <div data-partner={web.partner_id}
                                                                             onClick={() => {
                                                                                 openModal();
                                                                                 blockWebFalse();
                                                                                 setBlockedId(web.partner_id)
                                                                             }}
                                                                             className={classes.tooltipWebBlock}>
                                                                            <img src={noSymbol} alt="noSymbol"/>
                                                                        </div>
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger
                                                                        key={'search'}
                                                                        placement={'top'}
                                                                        overlay={
                                                                            <Tooltip>
                                                                                {getTranslatedWord(path, 'Статистика веба')}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Link onClick={clearOffers}
                                                                              to={`/${web.partner_id}`}
                                                                              className={classes.tooltipWebBlock}>
                                                                            <img src={search} alt="search"/>
                                                                        </Link>

                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td className="lcolumn"/>
                                                            </tr>
                                                        )
                                                    }) :
                                                    <tr>
                                                        <td>
                                                            <div
                                                                className='noData'>{getTranslatedWord(path, 'Нет данных за указанный период')}</div>
                                                        </td>
                                                    </tr>
                                                :
                                                <tr>
                                                    <td><Preloader style={preloader.webs}/></td>
                                                </tr>
                                            }

                                            </tbody>
                                            <thead>
                                            <tr className="table-header sort__table">
                                                <th className="fcolumn"/>
                                                <th>{getTranslatedWord(path, 'Итого')}:</th>
                                                <th>{websTotal.leads}</th>
                                                <th>{websTotal.ecpc}</th>
                                                <th>{websTotal.cr}</th>
                                                <th>{websTotal.clicks}</th>
                                                <th className='d-flex justify-content-center'/>
                                                <th className="lcolumn"/>
                                            </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                                <div className='pagination__block'>
                                    <Select2 onChangeCallBack={changeNumberShowOffers}/>
                                    <Pagination
                                        paginateNum={paginateNum}
                                        onPageChange={handlePageClick}
                                        pageCount={maxPage}
                                    />
                                </div>
                            </div>
                        </Container>
                    </main>
                </>
            }
            <Footer/>

            <Modal show={show} onHide={modalClose}>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <h3>{getTranslatedWord(path, 'Экспорт данных')}</h3>
                    {exportLink === '' ?
                        <>
                            <p>{getTranslatedWord(path, 'Не закрывайте окно — создаем файл для скачивания')}</p>
                            <div className='progress__block'>
                                <ProgressBar now={progress} label={`${progress}%`} visuallyHidden/>
                                <span>{`${progress}%`}</span>
                            </div>
                        </>
                        :
                        <div>
                            <p>{getTranslatedWord(path, 'Файл для скачивания готов!')}</p>
                            <a href={exportLink} onClick={() => {
                                setShow(false)
                            }}
                               className="primary-button">{getTranslatedWord(path, 'Скачать файл')}</a>
                        </div>

                    }
                </Modal.Body>
            </Modal>

            <ModalBlockWeb isWebBlocked={isWebBlocked} dispatch={dispatch} blockedId={blockedId} show={expand}
                           onHide={closeModal}/>

        </>
    )
}

export default compose(
    withAuthRedirect
)(WebsPage)