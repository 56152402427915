import {IS_AUTH_USER_REDUCER, SET_AUTH_USER_REDUCER, SET_ME_DATA_REDUCER} from './types'

let initialState = {
    token: '',
    refresh: '',
    data: '',
    username: '',
    isAuth: false,
    me: {
        login: '',
        partner_id: '',
        baseCurrency: '',
        offer: {
            id: '',
            name: '',
            image: '',
            country: ''
        }
    }
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH_USER_REDUCER:
            return {
                ...state,
                token: action.payload.data.token,
                refresh: action.payload.data.refresh,
                data: action.payload.data.created,
                username: action.username,
                isAuth: action.payload.token !== '' && action.payload.token !== null
            }
        case IS_AUTH_USER_REDUCER:
            return {
                ...state,
                token: action.payload.token,
                refresh: action.payload.refresh,
                data: action.payload.data,
                username: action.payload.username,
                isAuth: action.payload.token !== '' && action.payload.token !== null && action.payload.token !== undefined
            }
        case SET_ME_DATA_REDUCER:
            return {
                ...state, me: {...action.payload.data}
            }
        default:
            return state;
    }
}


