import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import routers from './config/routing/routes';
import {getTranslation, isAuthUser} from './store/actions';
import {GENERAL} from "./store/types";
import Preloader from "./components/common/Preloader/Preloader";


const App = () => {
    const dispatch = useDispatch()
    const translation = useSelector(state => state.data.translation);  // don`t delete!!!
    const lang = useSelector(state => state.data.lang)
    const path = GENERAL

    useEffect(() => {
        dispatch(getTranslation(path, lang))
    }, [lang])

    useEffect(() => {
        dispatch(isAuthUser(
            localStorage.getItem('token'),
            localStorage.getItem('refresh'),
            localStorage.getItem('data'),
            localStorage.getItem('username'),
        ))
    })


    return (
        Object.keys(translation) !== 0 ?
        <div className="App">
            <Router>
                <Switch>
                    {routers.map(router => (
                        <Route
                            key={router.path}
                            path={router.path}
                            exact={router.exact}
                            component={router.component}/>
                    ))}
                </Switch>
            </Router>
        </div> :
            <Preloader/>
    )
}

export default App;
