import {GET_HISTORY_OFFER} from './types'

const initialState = {
    offers: {},
    total: 0
};

export const historyReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HISTORY_OFFER:
            return {...state, offers: action.payload.offers, total: action.payload.total};
        default:
            return state;
    }
};