import React from "react";
import {getTranslatedWord} from "../../store/actions";
import {HOMEPAGE} from "../../store/types";

const TableBlock = ({offers}) => {
    const path = HOMEPAGE;


    return (
        <>


            <div className='table__wrapper'>

                <table className="table table-sortable table-stat table-borderless">
                    <thead>
                    <tr className="table-header">
                        <th className="fcolumn"/>
                        <th>{getTranslatedWord(path, 'Вебы')}</th>
                        <th>{getTranslatedWord(path, 'Дата клика')}</th>
                        <th>{getTranslatedWord(path, 'Дата конверсии')}</th>
                        <th>{getTranslatedWord(path, 'Transaction_id')}</th>
                        <th>{getTranslatedWord(path, 'Сумма')}</th>
                        <th>{getTranslatedWord(path, 'Статусы конверсии')}</th>
                        <th className="lcolumn"/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Object.keys(offers).length > 0 ?
                            offers.map(function (offer, i) {
                                return <tr key={i}>
                                    <td className="fcolumn"/>
                                    <td>{offer?.partnerId}</td>
                                    <td>{offer?.clickDate}</td>
                                    <td>{offer?.converseDate}</td>
                                    <td>{offer?.transactionId}</td>
                                    <td>{offer?.sum} {offer?.currency}</td>
                                    <td className={offer?.status}>{offer?.status}</td>
                                    <td className="lcolumn"/>
                                </tr>
                            })
                            :
                            <tr>
                                <td>
                                    <div className='noData'>{getTranslatedWord(path, 'Нет данных за указанный период')}</div>
                                </td>
                            </tr>
                    }
                    </tbody>
                </table>

            </div>

        </>

    )
}

export default TableBlock;