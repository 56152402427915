import {
    DELETE_DATA_NOTIFICATIONS,
    GET_HOME_OFFER,
    GET_OFFERS_DATA, GET_OFFERS_PDF_DATA,
    GET_TRANSLATION,
    IS_AUTH_USER_REDUCER, IS_PRELOADER_ACTIVE, IS_WEB_BLOCKED,
    RESPONSE_SUPPORT_SUCCESS,
    SET_AUTH_USER_REDUCER,
    SET_DATA_COURSES,
    SET_DATA_NOTIFICATIONS,
    SET_EXPORT_POSTBACK_LINK, SET_EXPORT_WEBS_LINK,
    SET_ME_DATA_REDUCER,
    SET_PICKED_DATE,
    SET_PROGRESS_MAIN, SET_PROGRESS_WEBS,
    SET_TRAFFIC_BY_TIME,
    SET_WEBS, SET_WEBS_TOTAL,
    TOGGLE_IS_FETCHING,
} from './types';
import {stopSubmit} from "redux-form";
import {authApi, dataApi, mainApi, websApi} from "../api/api";
import {generateCaseTranslate} from "../utils/translate/generateCaseTranslate";
import {store} from "../index";
import {STAT_URL} from "../api/apiUrls";

export const toggleIsFetching = (isFetching) => ({type: TOGGLE_IS_FETCHING, isFetching})

export const requestSupportThunk = (text) => {
    return dispatch => {
        mainApi.requestSupport(text)
            .then((response) => {
                if (response.data.status === 'success') {
                    dispatch({
                        type: RESPONSE_SUPPORT_SUCCESS,
                        payload: true
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export const blockWeb = (text, blockedId) => {
    return dispatch => {
        websApi.blockWeb(text, blockedId)
            .then(response => {
                if (response.data.status === 'success') {
                    dispatch({
                        type: IS_WEB_BLOCKED,
                        payload: true
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
    }


}

export const loginThunkCreator = (username, password) => {
    return (dispatch) => {
        authApi.login(username, password)
            .then(response => {
                if (response.data.status === 'success') {
                    localStorage.setItem('token', response.data.data.token)
                    localStorage.setItem('refresh', response.data.data.refresh)
                    localStorage.setItem('username', username)
                    localStorage.setItem('data', response.data.data.created)
                    dispatch({type: SET_AUTH_USER_REDUCER, payload: response.data, username})
                }
            })
            .catch(err => {
                if (err.response) {
                    dispatch(stopSubmit('login', {_error: 'Логин или пароль не верный'}))
                } else if (err.request) {
                    dispatch(stopSubmit('login', {_error: 'Ошибка сети'}))
                } else {
                    dispatch(stopSubmit('login', {_error: 'Неизвестная ошибка'}))
                }
            })
    }
}


export const logoutThunkCreator = () => {
    return () => {
        authApi.logout()
            .then(() => {
                console.log('success')
            })
            .finally(() => {
                localStorage.clear();
                document.location.reload();
            })
    }
}


export const setMeData = (data) => ({
    type: SET_ME_DATA_REDUCER, payload: {data}
})

export const setMeDataThunk = () => {
    return dispatch => {
        authApi.me()
            .then(response => {
                if (response.status === 200) {
                    localStorage.setItem('country', response.data.me.offer.country)
                    dispatch(setMeData(response.data.me))
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export const setNotifications = (notifications) => ({
    type: SET_DATA_NOTIFICATIONS, payload: notifications
})
export const deleteNotification = (id) => ({
    type: DELETE_DATA_NOTIFICATIONS, id
})


export const requestNotifications = () => {
    return dispatch => {
        dataApi.getNotifications()
            .then(response => {
                if (response.status === 200) {
                    dispatch(setNotifications(response.data.data.items))
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export const readNotificationThunk = (id) => {
    return dispatch => {
        dataApi.readNotification(id)
            .then(response => {
                if (response.data.status === 'success') {
                    dispatch(requestNotifications())
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export const readAllNotificationsThunk = () => {
    return dispatch => {
        dataApi.readAllNotifications()
            .then(response => {
                if (response.data.status === 'success') {
                    dispatch(requestNotifications())
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
}


export const getTranslation = (path, lang) => {
    return dispatch => {
        dataApi.getTranslation(path, lang)
            .then(response => {
                let translate = {}
                translate[path] = {};
                if (response.data.status === 'success' && response.data.data.items !== undefined) {
                    response.data.data.items.forEach((item) => {
                        translate[path][generateCaseTranslate(item.word)] = item.translation === undefined ? '' : item.translation
                    })
                }
                dispatch({type: GET_TRANSLATION, payload: translate})
            })
            .catch(err => console.log(err))
    }
}

export const getTranslatedWord = (path, word) => {
    const translation = store.getState().data.translation;
    if (translation !== undefined && translation[path] !== undefined) {
        if (translation[path][generateCaseTranslate(word)] !== undefined) {
            if (translation[path][generateCaseTranslate(word)] !== '') {
                return translation[path][generateCaseTranslate(word)];
            } else if (translation[path][generateCaseTranslate(word)] === '') {
                return word
            }
        } else {
            const languages = ['ru', 'ua', 'en', 'pl']
            languages.forEach(l => {
                if (localStorage.getItem(path + word + l) == null) {
                    localStorage.setItem(path + word + l, path + word + l)
                    dataApi.addWordToTranslate(path, word, l)
                        .catch(err => {
                            console.log(err)
                        })
                }
            })
        }

    }

    return word
}

export const isAuthUser = (token, refresh, data, username) => ({
    type: IS_AUTH_USER_REDUCER, payload: {token, refresh, data, username}
})


export const setCourses = (courses) => ({type: SET_DATA_COURSES, courses})

export const requestCourses = () => {
    return (dispatch) => {
        dataApi.getCourses()
            .then(response => {
                dispatch(setCourses(response.data.data))
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export const setPickedDate = (dateStart, dateEnd) => ({type: SET_PICKED_DATE, payload: {dateStart, dateEnd}})

export const setTrafficByTimeGraph = (traffic, leadsSum, avarageCR, avarageECPC) => ({
    type: SET_TRAFFIC_BY_TIME, traffic, leadsSum, avarageCR, avarageECPC
})

export const getTrafficByTimeGraph = (query, pid) => {
    return dispatch => {
        if (pid !== null) {
            query['query[pid]'] = pid
        }
        dataApi.queryTrafficByTime(query)
            .then(response => {
                if (response && response.status === 200) {
                    let traffic = response.data.data,
                        {avgCr, avgEcpc, clicks} = response.data.total

                    let dataState = {
                        data: {
                            labels: Object.keys(traffic).map(e => e.split(" ")[0]),
                            datasets: [{
                                data: Object.keys(traffic).map(e => traffic[e].cr),
                                label: "CR",
                                borderColor: "#97D0FF",
                                fill: true,
                                backgroundColor: "rgba(151, 208, 255, .4)",
                                tension: 0.4,
                            }, {
                                data: Object.keys(traffic).map(e => traffic[e].ecpc),
                                label: "EPC",
                                borderColor: "#AFDE8D",
                                minHeight: 100,
                                fill: true,
                                backgroundColor: "rgba(175, 222, 141, 0.4)",
                                tension: 0.4,
                                yAxisID: 'y-axis-right'
                            }, {
                                data: Object.keys(traffic).map(e => traffic[e].clicks),
                                label: "Uniques",
                                borderColor: "#66B72C",
                                fill: true,
                                backgroundColor: "rgba(102, 183, 44, .4)",
                                tension: 0.4,
                                yAxisID: 'y-axis-right'
                            }, {
                                data: Object.keys(traffic).map(e => traffic[e].postbacks),
                                label: "Leads",
                                borderColor: "#F0B11F",
                                fill: true,
                                backgroundColor: "rgba(240, 177, 31, 0.4)",
                                tension: 0.4,
                                type: 'bar',
                            }
                            ],
                        },

                        options: {
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    position: 'top',
                                    labels: {
                                        boxWidth: 12,
                                        padding: 20,
                                        margin: 100,
                                    },
                                },
                            },
                            scales: {

                                x: {
                                    grid: {
                                        color: 'transparent'
                                    },
                                    ticks: {
                                        display: true,
                                    },

                                },
                                y: {
                                    ticks: {
                                        fontStyle: "6px",
                                        display: true,
                                        beginAtZero: true,
                                    },
                                    type: 'linear',
                                    display: true,
                                    position: 'right',
                                    id: 'y-axis-right',

                                }
                            },
                        },

                    };

                    dispatch(setTrafficByTimeGraph(dataState, clicks, avgCr, avgEcpc))
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export const getOffersData = (offers) => ({
    type: GET_OFFERS_DATA, payload: offers
})

export const getOffersPdfData = (offersPdf) => ({
    type: GET_OFFERS_PDF_DATA, payload: offersPdf
})

const offersDataChart = (data) => {

    return {
        data: {
            labels: Object.keys(data).map(e => e.split(" ")[0]),
            datasets: [
                {
                    data: Object.keys(data).map(e => typeof data[e] !== "undefined" ? data[e].cr : 0),
                    label: "CR",
                    borderColor: "#97D0FF",
                    fill: true,
                    backgroundColor: "rgba(151, 208, 255, .4)",
                    tension: 0.4,
                }, {
                    data: Object.keys(data).map(e => data[e].ecpc),
                    label: "EPC",
                    borderColor: "#AFDE8D",
                    fill: true,
                    backgroundColor: "rgba(175, 222, 141, 0.4)",
                    tension: 0.4,
                    yAxisID: 'y-axis-right'
                }
            ]
        },

        options: {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'top',
                    labels: {
                        boxWidth: 12,
                        padding: 20,
                    }
                }
            },
            scales: {
                x: {
                    grid: {
                        color: 'transparent'
                    },
                    ticks: {
                        display: true
                    }
                },
                y: {
                    ticks: {
                        fontStyle: "6px",
                        display: true,
                        beginAtZero: true
                    },
                    type: 'linear',
                    display: true,
                    position: 'right',
                    id: 'y-axis-right',
                }
            }
        }
    }
}


export const queryOffersDataThunk = (page, perPage, pid, startDate, endDate, currency, pdf) => {
    return dispatch => {
        let query = {}
        const country = localStorage.getItem('country')
        const hash = localStorage.getItem('hashOffers')
        dataApi.queryOffers({page, perPage, orderBy: 'uniqs', orderDir: 'desc', 'query[country]': country, hash})
            .then(response => {
                if (response.data.data.items === undefined) {
                    dispatch({
                        type: IS_PRELOADER_ACTIVE,
                        payload: false
                    })
                    return
                }
                let firstResponse = response.data.data.items;
                let ids = response.data.data.items.map(e => e.id);
                if (pid !== null && pid !== undefined) {
                    query['query[pid]'] = pid
                }
                let process = [...ids.map(id => {
                        let newQuery = {
                            ...query,
                            ['query[offers][]']: id,
                            ['query[date_from]']: startDate,
                            ['query[date_to]']: endDate,
                            currency
                        }
                        return dataApi.queryTrafficByTime(newQuery)
                    }
                )];

                Promise
                    .all(process)
                    .then(responses => {
                        responses.map((resp, i) => {
                            if (resp.status === 200) {
                                firstResponse[i].chart = offersDataChart(resp.data.data);
                                firstResponse[i].cr = resp.data.total.avgCr
                                firstResponse[i].ecpc = resp.data.total.avgEcpc
                            } else {
                                firstResponse[i].chart = offersDataChart([]);
                            }

                        })
                        return firstResponse;
                    })
                    .then((res) => {
                        if (!pdf) {
                            dispatch(getOffersData(res))
                        } else {
                            dispatch(getOffersPdfData(res))
                        }

                    })
                    .catch(err => {
                        console.log(err)
                    })


            })
            .catch(err => {
                console.log(err)
            })
    }
}

export const uploadOffersThunk = (query) => {
    if (localStorage.getItem('hashOffers') !== undefined && localStorage.getItem('hashOffers') !== '') {
        dataApi.clearOffers(localStorage.getItem('hashOffers'))
    }
    return uploadAllOffers(query)
        .then(hash => {
            localStorage.setItem('hashOffers', hash)
        })
}

const uploadAllOffers = (query, rslv, rej) => {
    return new Promise(function (resolve, reject) {

        rslv = rslv ? rslv : resolve;
        rej = rej ? rej : reject;

        dataApi.uploadOffers({
            page: 1,
            perPage: 1000,
            ...query
        })
            .then(function (response) {

                if (response.status === 200) {

                    if (response.data.data.paginaton.page < response.data.data.paginaton.maxPage) {
                        query = {
                            ...query,
                            page: response.data.data.paginaton.nextPage,
                            hash: response.data.data.hash
                        }
                        uploadAllOffers(query, rslv, rej);
                    } else {
                        rslv(response.data.data.hash);
                    }

                } else {
                    rej(response);
                }

            })

    });

}

export const setWebs = (data, total, max) => ({
    type: SET_WEBS, payload: {webs: data, total: total, max: max}
})

export const setWebsTotal = (totalObj) => ({
    type: SET_WEBS_TOTAL,
    payload: totalObj
})

export const queryWebs = (page, perPage, sort) => {
    return dispatch => {
        let hash = localStorage.getItem('hashWebs')
        dispatch(toggleIsFetching(true))
        websApi.websTotal(hash)
            .then((res) => {
                if (res.data) {
                    dispatch(setWebsTotal(res.data.data))
                }
            })
        websApi.queryWebs(hash, page, perPage, sort)
            .then(response => {
                    dispatch(toggleIsFetching(false))
                    let data = {}
                    let total = 0;
                    let max = 0;
                    if (response.status === 200) {
                        data = response.data.data.items !== undefined ? response.data.data.items : {};
                        total = response.data.data.count !== undefined ? response.data.data.count : 0;
                        max = response.data?.data?.pagination?.max !== undefined ? response.data?.data?.pagination?.max : 0;
                    }
                    dispatch(setWebs(data, total, max))
                }
            )
            .catch(err => {
                // console.log(err)
                dispatch(setWebs([], 0, 0))

            })
    }
}

export const uploadWebsThunk = (query) => {

    if (localStorage.getItem('hashWebs')) {
        websApi.clearWebs(localStorage.getItem('hashWebs'))
    }
    return uploadAllWebs(query)
        .then(hash => {
            localStorage.setItem('hashWebs', hash)
        })
}

const uploadAllWebs = (query, rslv, rej) => {
    return new Promise(function (resolve, reject) {

        rslv = rslv ? rslv : resolve;
        rej = rej ? rej : reject;

        websApi.uploadWebs({
            page: 1,
            perPage: 1000,
            ...query
        })
            .then(function (response) {

                if (response.status === 200) {

                    if (response.data.data.paginaton.page < response.data.data.paginaton.maxPage) {
                        query = {
                            ...query,
                            page: response.data.data.paginaton.nextPage,
                            hash: response.data.data.hash
                        }
                        uploadAllWebs(query, rslv, rej);

                    } else {
                        rslv(response.data.data.hash);
                    }

                } else {
                    rej(response);
                }

            })

    });

}

export const exportPostbacksThunk = (query) => {
    return dispatch => {
        return exportPostbacks(query, (progress) => {
            dispatch({
                type: SET_PROGRESS_MAIN,
                payload: progress,
            })
        })
            .then(link => {
                dispatch({
                    type: SET_EXPORT_POSTBACK_LINK,
                    payload: `${STAT_URL}${link}`,
                })
            })
    }
}
const exportPostbacks = (query, dsptch, rslv, rej) => {
    return new Promise(function (resolve, reject) {
        rslv = rslv ? rslv : resolve;
        rej = rej ? rej : reject;
        dsptch = typeof dsptch == "function" ? dsptch : (progress) => {
        };

        dataApi.exportPostback({
            ...query
        })

            .then(function (response) {

                if (response.status === 200) {
                    if (response.data.data.pagination.page < response.data.data.pagination.maxPage) {
                        dsptch(response.data.data.progress);
                        query = {
                            ...query,
                            page: response.data.data.pagination.nextPage,
                            hash: response.data.data.hash,
                            scrollId: response.data.data.scrollId,
                        }
                        exportPostbacks(query, dsptch, rslv, rej);

                    } else {
                        dsptch(response.data.data.progress);
                        rslv(response.data.data.link);
                    }

                } else {
                    rej(response);
                }

            })
    })

}

export const exportWebsThunk = (query) => {
    return dispatch => {
        return exportWebs(query, (progress) => {
            dispatch({
                type: SET_PROGRESS_WEBS,
                payload: progress,
            })
        })
            .then(link => {
                dispatch({
                    type: SET_EXPORT_WEBS_LINK,
                    payload: `${STAT_URL}${link}`,
                })
            })
    }
}
const exportWebs = (query, dsptch, rslv, rej) => {
    return new Promise(function (resolve, reject) {
        rslv = rslv ? rslv : resolve;
        rej = rej ? rej : reject;
        dsptch = typeof dsptch == "function" ? dsptch : (progress) => {
        };

        websApi.exportWebs({
            ...query
        })

            .then(function (response) {

                if (response.status === 200) {
                    if (response.data.data.pagination.page < response.data.data.pagination.maxPage) {
                        dsptch(response.data.data.progress);
                        query = {
                            ...query,
                            page: response.data.data.pagination.nextPage,
                            hash: response.data.data.hash,
                            scrollId: response.data.data.scrollId,
                        }
                        exportWebs(query, dsptch, rslv, rej);

                    } else {
                        dsptch(response.data.data.progress);
                        rslv(response.data.data.link);
                    }

                } else {
                    rej(response);
                }

            })
    })

}

export const getTableOffers = (dataFrom, dataTo, paginateNum, NumberShowOffers, filterSelected) => {
    let dataParams = {}
    let queryString = ''


    Object.values(filterSelected).forEach((item, i) => {
        if (dataParams[item.filter] === undefined) dataParams[item.filter] = {};
        dataParams[item.filter][i] = item.name
    })

    if (dataParams.status !== undefined) {
        Object.values(dataParams.status).forEach((el, i) => {
            queryString += `&query[status][]=${el}`;
        })
    }

    if (dataParams.parentId !== undefined) {
        Object.values(dataParams.parentId).forEach((el, i) => {
            queryString += `&query[partnerId][]=${el}`;
        })
    }

    if (dataParams.transactionId !== undefined) {
        Object.values(dataParams.transactionId).forEach((el, i) => {
            queryString += `&query[transactionId][]=${el}`;
        })
    }
    return dispatch => {
        dataApi.getTableOffers(dataFrom, dataTo, paginateNum, NumberShowOffers, queryString)
            .then(response => {

                let data = {}
                let total = 0;
                let max = 0;
                if (response.data.status === 'success') {
                    data = response.data.data.items !== undefined ? response.data.data.items : {};
                    total = response.data.data.count !== undefined ? response.data.data.count : 0;
                    max = response.data?.data?.pagination?.max !== undefined ? response.data?.data?.pagination?.max : 0;
                    dispatch({
                        type: GET_HOME_OFFER,
                        payload: {offers: data, total: total, max: max}
                    })
                }


            })
            .catch(err => {
                console.log(err)
            })
    }
}


// export function changeRandomInfo() {
//     return {
//         type: GET_TEST_INFO,
//         payload: {
//             int: Math.random() * 100,
//             path: API_PATH
//         }
//     }
// }
//
//
// export function getOffersForBanners(params) {
//     let data = [];
//
//     for (let i = 0; i < params.count; i++) {
//         data.push({
//             id: i,
//             name: 'Баннер',
//             status: 'published',
//             countClick: Math.round(Math.random() * 100),
//             countView: Math.round(Math.random() * 1000)
//         })
//     }
//
//
//     return {
//         type: GET_BANNERS_OFFER,
//         payload: {offers: data, total: 2320}
//     }
// }
//
// export function getOffersForDocuments(params) {
//     let data = [];
//
//     for (let i = 0; i < params.count; i++) {
//         data.push({
//             id: i,
//             docType: 'Тип документа',
//             date: Math.round(Math.random() * 100),
//             note: 'Примечание'
//         })
//     }
//
//     return {
//         type: GET_DOCUMENTS_OFFER,
//         payload: {offers: data, total: 2320}
//     }
// }
//
// export function getOffersForHistory(params) {
//     let data = [];
//
//     for (let i = 0; i < params.count; i++) {
//         data.push({
//             id: i,
//             date: Math.round(Math.random() * 100),
//             userName: 'Веб',
//             bid: Math.round(Math.random() * 10)
//         })
//     }
//
//     return {
//         type: GET_HISTORY_OFFER,
//         payload: {offers: data, total: 2320}
//     }
// }


