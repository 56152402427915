import React from 'react';
import {connect} from "react-redux";
import LoginPage from "../views/LoginPage/LoginPage";
import { withRouter } from "react-router";

let mapStateToPropsForRedirect = (state) => ({
    isAuth: state.auth.isAuth
})

export const withAuthRedirect = (Component) => {


    class RedirectComponent extends React.Component {

        constructor(props, context) {
            super(props, context);
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            if (params.token !== undefined) {
                localStorage.setItem('token', params?.token ?? '')
                this.props.history.push("/");
            }

        }

        render() {
            return (
                <div>
                    {this.props.isAuth ? <Component {...this.props}/> : <LoginPage />}
                </div>
            )
        }
    }


    return connect(mapStateToPropsForRedirect)(withRouter(RedirectComponent))
}
