import {combineReducers} from 'redux';
import {mainReducer} from './mainReducer'
import {bannersReducer} from './bannersReducer'
import {documentsReducer} from "./documentsReducer";
import {websReducer} from "./websReducer";
import {historyReducer} from "./historyReducer";
import {authReducer} from './authReducer';
import {dataReducer} from "./dataReducer";
import { reducer as formReducer } from 'redux-form';


export const rootReducer = combineReducers({
    main: mainReducer,
    webs: websReducer,
    banners: bannersReducer,
    documents: documentsReducer,
    history: historyReducer,
    auth: authReducer,
    data: dataReducer,
    form: formReducer
});