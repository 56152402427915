import {
    CLEAR_OFFERS,
    GET_OFFERS_DATA, GET_OFFERS_PDF_DATA,
    GET_TRANSLATION,
    SET_DATA_COURSES,
    SET_DATA_NOTIFICATIONS,
    SET_LANG,
    SET_PICKED_DATE,
    SET_TRAFFIC_BY_TIME
} from "./types";

import moment from "moment";


let start
let end

if (localStorage.getItem('start') && localStorage.getItem('end')) {
    start = localStorage.getItem('start')
    end = localStorage.getItem('end')
} else {
    start = moment().startOf('month').format('YYYY-MM-DD')
    end = moment().format('YYYY-MM-DD');
}

const initialState = {
    courses: [],
    notifications: [],
    lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ru',
    allLang: ['ru', 'ua', 'en'],
    translation: {},
    isTranslated: false,
    traffic: {},
    leadsSum: 0,
    avarageCR: 0,
    avarageECPC: 0,
    date: {
        dateStart: start,
        dateEnd: end,
    },
    offers: [],
    offersPdf: []
}

export const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LANG:
            return {...state, lang: action.payload}
        case GET_TRANSLATION:
            return {...state, translation: {...state.translation, ...action.payload}, isTranslated: true}
        case SET_DATA_COURSES:
            return {...state, courses: [...action.courses]}
        case SET_DATA_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload ? action.payload.filter(item => item.news_id) : []
            };
        case SET_TRAFFIC_BY_TIME:
            return {
                ...state,
                traffic: {...action.traffic},
                leadsSum: action.leadsSum,
                avarageCR: action.avarageCR,
                avarageECPC: action.avarageECPC
            }
        case SET_PICKED_DATE:
            return {
                ...state, date: {...action.payload}, offers: [], page: 1
            }
        case GET_OFFERS_DATA:
            return {
                ...state, offers: [...state.offers, ...action.payload]
            }
        case GET_OFFERS_PDF_DATA:
            return {
                ...state, offersPdf: [...action.payload]
            }
        case CLEAR_OFFERS:
            return {
                ...state, offers: []
            }
        default:
            return state;
    }

}