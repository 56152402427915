import jsPDF from "jspdf";
import pdlLogo from "../../images/pdl-big-logo-by-trionika.jpg";
import html2canvas from "html2canvas";

export async function generatePdf() {
    const $pageTitle = document.querySelector('.pdf__title h2')
    const $pdfDate = document.querySelector('.pdf__date')
    const $mainChart = document.querySelector('.chart-main-container--wrapper')
    const $charts = document.getElementsByClassName('chart__offers--item')


    let doc = new jsPDF();
    let counter = 0;
    let row = 2;
    let page = 1;
    let charts = Array.from($charts)

    let renderOfferRow = function (name, img, row, col, rowPad = 0) {

        let rowStart = 56 * row;
        let colStart = 15 + (col === 1 ? 97 : 0);

        doc.addImage(img, 'JPEG', colStart, rowStart - rowPad, 82, 60);

    };

    let addHeadTitles = function () {

        doc.addImage(getCanvasText($pageTitle.innerHTML, "20").data, 'JPEG', 15, 10);
        doc.addImage(getCanvasText($pdfDate.innerHTML, "14").data, 'JPEG', 15, 20);
        doc.addImage(pdlLogo, 'JPEG', 160, 10, 33, 10);

    }
    let addFooterTitles = function () {

        let text = getCanvasText("powered by PDL-Profit.com", "10");

        doc.addImage(text.data, 'JPEG', 105 - ((text.wightInMM) / 2), 287);

    };

    addHeadTitles();

    doc.addImage(canvasToImage(await html2canvas($mainChart)), 'JPEG', 30, 25, 150, 85);

    addFooterTitles();

    for (let chart of charts) {
        counter++;
        if ((page === 1 && counter === 7) || (page > 1 && counter === 9)) {
            counter = 1;
            row = 2;
            page++;
            doc.addPage();
            doc.setPage(page);
            addHeadTitles();
            addFooterTitles();
        }
        renderOfferRow(chart.getAttribute('data-name'), canvasToImage(await html2canvas(chart)), row, (counter % 2 === 0 ? 1 : 0), (page - 1) ? 70 : 0);

        row += (counter % 2 === 0 ? 1 : 0);
    }

    return doc;

}

function canvasToImage(canv) {

    let canvas = document.createElement("canvas");
    canvas.width = canv.width;
    canvas.height = canv.height;
    document.body.appendChild(canvas);
    let ctx = canvas.getContext("2d");

    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canv.width, canv.height);
    ctx.drawImage(canv, 0, 0);

    let data = canvas.toDataURL("image/jpeg;base64;");

    canvas.remove();

    return data;

}

function getCanvasText(text, fontSize) {

    let canvas = document.createElement("canvas");
    canvas.width = 500;
    canvas.height = fontSize + 5;
    document.body.appendChild(canvas);
    let ctx = canvas.getContext("2d");
    ctx.font = fontSize + "px Arial";
    ctx.fillText(text, 0, fontSize);
    let width = ctx.measureText(text).width;
    let data = canvasToImage(canvas);

    canvas.remove();

    return {
        wightInMM: width * 0.264583,
        width,
        data
    };
}