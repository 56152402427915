import React, {useEffect} from 'react';
import classes from './../Sidebar.module.css'
import {NavLink, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getTranslatedWord, setMeDataThunk} from "../../../store/actions";
import {ARE_OFFERS_LOADED, CLEAR_OFFERS, GENERAL, IS_PRELOADER_ACTIVE} from "../../../store/types";

const managersPath = {};

function importAll(r, paths) {
    r.keys().forEach((key) => (paths[key.slice(2)] = r(key)));
}

importAll(require.context('../../../images/managers/', true), managersPath);

let managersImages = {};
Object.keys(managersPath).map(path => {
    managersImages[path.split(".")[0]] = managersPath[path];
});

const SidebarSubmenu = (props) => {
    const dispatch = useDispatch()
    const path = GENERAL
    const {pid} = useParams();
    const translation = useSelector(state => state.data.translation)
    const me = useSelector(state => state.auth.me)

    const clearOffers = () => {
        dispatch({type: CLEAR_OFFERS})
        dispatch({
            type: IS_PRELOADER_ACTIVE,
            payload: true
        })
        dispatch({type: ARE_OFFERS_LOADED, payload: false})
    }

    useEffect(() => {
        dispatch(setMeDataThunk())
    }, [])


    return (

        <div
            className={`${classes.sidebar__submenu} ${props.ActiveSidebar ? classes.toggled : ""}  d-none  d-md-flex flex-column h-100`}>
            <div className={`${classes.sidebar__submenu_wrap} sidebar__menu d-flex flex-column`}>
                <NavLink to={`${pid ? `/${pid}` : '/'}`} exact={true} onClick={() => clearOffers()} >{getTranslatedWord(path, 'Статистика')}</NavLink>
                <NavLink to="/webs/1">{getTranslatedWord(path, 'Вебы')}</NavLink>
                {/*<NavLink to="/documents">{getTranslatedWord(path, 'Документы')}</NavLink>*/}
                {/*<NavLink to="/banners">{getTranslatedWord(path, 'Баннеры')}</NavLink>*/}
                {/*<NavLink to="/history">{getTranslatedWord(path, 'История ставок')}</NavLink>*/}
            </div>
            {me.manager !== null && me.manager !== undefined ?
                <div className={classes.sidebar__manager}>
                    <span className={classes.sidebar__manager_title}>{getTranslatedWord(path, 'Ваш менеджер')}</span>
                    <div className='d-flex'>
                        <div className={classes.photo}>
                            {managersImages[me?.manager?.id] ?
                                <img src={managersImages[me?.manager?.id]} alt="manager"/>
                                :
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M30 15C30 6.70967 23.2912 0 15 0C6.70967 0 0 6.70876 0 15C0 23.2169 6.65268 30 15 30C23.313 30 30 23.2546 30 15ZM15 1.75781C22.3018 1.75781 28.2422 7.69821 28.2422 15C28.2422 17.6711 27.4477 20.2384 25.9744 22.413C20.0583 16.0492 9.95247 16.0378 4.02557 22.413C2.55226 20.2384 1.75781 17.6711 1.75781 15C1.75781 7.69821 7.69821 1.75781 15 1.75781ZM5.12192 23.8184C10.3821 17.9182 19.6193 17.9196 24.8779 23.8184C19.6001 29.7231 10.402 29.7249 5.12192 23.8184Z"
                                        fill="#212F57"/>
                                    <path
                                        d="M15 16.4314C17.8357 16.4314 20.1429 14.4538 20.1429 12.0232V10.5538C20.1429 8.1232 17.8357 6.14565 15 6.14565C12.1643 6.14565 9.85714 8.1232 9.85714 10.5538V12.0232C9.85714 14.4538 12.1643 16.4314 15 16.4314ZM11.5714 10.5538C11.5714 8.93327 13.1094 7.61503 15 7.61503C16.8906 7.61503 18.4286 8.93327 18.4286 10.5538V12.0232C18.4286 13.6437 16.8906 14.962 15 14.962C13.1094 14.962 11.5714 13.6437 11.5714 12.0232V10.5538Z"
                                        fill="#212F57"/>
                                </svg>
                            }

                        </div>


                        <div>
                            <div className={classes.manager__name}>
                                {me?.manager?.name}
                            </div>
                            <div className={classes.manager__links}>
                                {
                                    me?.manager && me?.manager?.email?.length !== 0 ?
                                        <a href={`mailto:${me?.manager?.email}`}>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11.9492 2.05078C10.6285 0.727344 8.87031 0 7 0C5.12969 0 3.37148 0.727344 2.05078 2.05078C0.727344 3.37148 0 5.12969 0 7C0 8.87031 0.727344 10.6285 2.05078 11.9492C3.37422 13.2727 5.12969 14 7 14C7.22695 14 7.41016 13.8168 7.41016 13.5898C7.41016 13.3629 7.22695 13.1797 7 13.1797C3.59297 13.1797 0.820312 10.407 0.820312 7C0.820312 3.59297 3.59297 0.820312 7 0.820312C10.407 0.820312 13.1797 3.59297 13.1797 7V8.64609C13.1797 9.32969 12.6246 9.88203 11.9437 9.88203C11.2629 9.88203 10.7078 9.32695 10.7078 8.64609V7C10.7078 4.95742 9.04531 3.29492 7.00273 3.29492C4.96016 3.29492 3.29492 4.95742 3.29492 7C3.29492 9.04258 4.95742 10.7051 7 10.7051C8.21133 10.7051 9.28867 10.1199 9.9668 9.21758C10.2156 10.0762 11.0059 10.7051 11.9437 10.7051C13.0785 10.7051 14 9.78086 14 8.64883V7C14 5.12969 13.2727 3.37148 11.9492 2.05078ZM7 9.88477C5.40859 9.88477 4.11523 8.59141 4.11523 7C4.11523 5.40859 5.40859 4.11523 7 4.11523C8.59141 4.11523 9.88477 5.40859 9.88477 7C9.88477 8.59141 8.59141 9.88477 7 9.88477Z"
                                                    fill="#212F57"/>
                                            </svg>
                                        </a> :
                                        ''
                                }

                                {
                                    me?.manager && me?.manager?.contact.length !== 0 && me?.manager?.contact[0] !== ''?
                                        <a href={(me?.manager?.contact[0] === 'telegram') ? `https://telegram.me/${me?.manager?.contact[1]}` : ''}>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0)">
                                                    <path
                                                        d="M0.242094 6.53129L3.66568 8.23754C3.79809 8.30287 3.95443 8.29821 4.08101 8.22412L7.12834 6.45254L5.28384 8.08704C5.18993 8.17046 5.13626 8.28946 5.13626 8.41487V12.3961C5.13626 12.8161 5.67234 12.9929 5.92376 12.6592L7.40368 10.6916L11.0635 12.7759C11.3202 12.9235 11.6492 12.7753 11.7087 12.4819L13.9913 1.10687C14.0595 0.76504 13.7194 0.486207 13.4033 0.613373L0.278261 5.73212C-0.0740725 5.86979 -0.0968225 6.36271 0.242094 6.53129ZM12.9763 1.71937L10.9702 11.716L7.50226 9.74079C7.31326 9.63287 7.07001 9.68071 6.93584 9.85804L6.01126 11.0871V8.61146L10.9993 4.19212C11.3943 3.84271 10.9404 3.22146 10.4895 3.48629L3.84534 7.34912L1.51668 6.18887L12.9763 1.71937Z"
                                                        fill="#212F57"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0">
                                                        <rect width="14" height="14" fill="#212F57"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                        :
                                        ''
                                }
                            </div>
                        </div>
                    </div>
                </div> :
                <div className={classes.sidebar__manager}>No manager</div>
            }
            <div onClick={() => props.changeSidebar()}
                 className={`${classes.sidebar__close_menu} d-flex align-items-center`}>
                <span>{getTranslatedWord(path, 'Свернуть меню')}</span>
            </div>
        </div>
    )
}

export default SidebarSubmenu;