import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Container from "react-bootstrap/Container";
import {Nav, Navbar} from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown'
import {NavLink, useParams} from "react-router-dom";
import classes from './Header.module.css';
import logo from './../../images/logo-pdl.svg'
import manager17369 from '../../images/managers/17369.jpg'
import manager27194 from '../../images/managers/27194.jpg'

import {
    getTranslatedWord,
    logoutThunkCreator,
    readAllNotificationsThunk,
    readNotificationThunk,
    requestCourses,
    requestNotifications
} from "../../store/actions";
import {
    ARE_OFFERS_LOADED,
    CLEAR_OFFERS,
    GENERAL,
    IS_PRELOADER_ACTIVE,
    RESPONSE_SUPPORT_SUCCESS,
    SET_LANG
} from "../../store/types";
import ModalSupport from "../common/Modals/ModalSupport/ModalSupport";



const Header = (props) => {
    const path = GENERAL
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [toggleNotifications, setToggleNotifications] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const {pid} = useParams();

    const logout = () => {
        dispatch(logoutThunkCreator())
    }


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const toggleNotification = () => setToggleNotifications(!toggleNotifications)


    const userName = useSelector(state => state.auth.username)
    const courses = useSelector(state => state.data.courses)
    const notifications = useSelector(state => state.data.notifications)
    const me = useSelector(state => state.auth.me)
    const lang = useSelector(state => state.data.lang)
    const allLang = useSelector(state => state.data.allLang)
    const translation = useSelector(state => state.data.translation)


    const readNotification = (id) => {
        dispatch(readNotificationThunk(id))
    }
    const readAllNotifications = () => {
        dispatch(readAllNotificationsThunk())
    }

    const setLangTranslation = (e) => {
        e.preventDefault()
        if (e.target.classList.contains('dropdown-item') || e.target.classList.contains('lang-item')) {
            let lang = e.target.innerText.toLowerCase()
            localStorage.setItem('lang', lang)
            dispatch({type: SET_LANG, payload: lang})
        }
        location.reload()
    }

    const newSupportRequest = () => {
        dispatch({
            type: RESPONSE_SUPPORT_SUCCESS,
            payload: false
        })
    }

    const clearOffers = () => {
        dispatch({type: CLEAR_OFFERS})
        dispatch({
            type: IS_PRELOADER_ACTIVE,
            payload: true
        })
        dispatch({type: ARE_OFFERS_LOADED, payload: false})
    }

    useEffect(() => {
        dispatch(requestCourses())
    }, [])

    useEffect(() => {
        dispatch(requestNotifications())
    }, [])

    return (<>
        <header className={classes.header}>
            <Container fluid='md'>
                <Navbar expanded={expanded} className={classes.navbar} expand="md">
                    <NavLink className={`${classes.navbar__brand} d-flex align-items-center`} to="/">
                        <img src={logo} alt="logo"/>
                    </NavLink>
                    <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")}>
                        <svg width="24" height="16" viewBox="0 0 24 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 13.334H0V16.0007H24V13.334Z" fill="white"/>
                            <path d="M24 6.66602H0V9.3327H24V6.66602Z" fill="white"/>
                            <path d="M24 0H0V2.66668H24V0Z" fill="white"/>
                        </svg>
                    </Navbar.Toggle>
                    <Navbar.Collapse className={`${classes.navbarNav} justify-content-end`}>
                        <div
                            className={`${classes.navbar__wrapper} d-flex flex-column flex-md-row  align-items-md-center justify-content-end`}>
                            <Nav.Item className={`${classes.navbar__currency} ${classes.navbar__item}`}>
                                {courses.length > 0 ?
                                    <div
                                        className={`${classes.currency__desktop} d-md-none d-xl-block currency-desktop`}>
                                        {
                                            courses.map((course, i) =>
                                                <span key={i}>{course.currency}: {course.course.toFixed(3)}</span>)
                                        }

                                    </div>
                                    :
                                    ' '
                                }


                                <Dropdown className='d-none d-md-block d-xl-none'>
                                    <Dropdown.Toggle id="dropdownCurrency">
                                        <svg width="25" height="20" viewBox="0 0 25 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0)">
                                                <path
                                                    d="M23.7495 19V0.5C23.7495 0.223875 23.5629 0 23.3328 0H19.1662C18.9361 0 18.7495 0.223875 18.7495 0.5V19H17.9162V7.5C17.9162 7.22388 17.7296 7 17.4995 7H13.3328C13.1027 7 12.9162 7.22388 12.9162 7.5V19H12.0828V2.5C12.0828 2.22388 11.8963 2 11.6662 2H7.49951C7.26941 2 7.08284 2.22388 7.08284 2.5V19H6.24951V9.5C6.24951 9.22388 6.06295 9 5.83284 9H1.66618C1.43607 9 1.24951 9.22388 1.24951 9.5V19H-0.000488281V20H24.9995V19H23.7495ZM5.41618 19H2.08284V10H5.41618V19ZM11.2495 19H7.91618V3H11.2495V19ZM17.0828 19H13.7495V8H17.0828V19ZM22.9162 19H19.5828V1H22.9162V19Z"
                                                    fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="25" height="20" fill="white"
                                                          transform="translate(-0.000488281)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="dropdown-item">
                                            {
                                                courses.map((course, i) =>
                                                    <span key={i}>
                                                        {course.currency}: {course.course.toFixed(2)}
                                                    </span>)
                                            }
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav.Item>

                            <Nav.Item
                                className={`${classes.navbar__info} ${classes.navbar__item} d-none d-md-flex`}>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdownNotification">

                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0)">
                                                <path
                                                    d="M11.2497 3.48333C11.0197 3.48333 10.833 3.29667 10.833 3.06667V1.66667C10.833 1.2075 10.4588 0.833333 9.99967 0.833333C9.5405 0.833333 9.16634 1.2075 9.16634 1.66667V3.06667C9.16634 3.29667 8.97967 3.48333 8.74967 3.48333C8.51967 3.48333 8.33301 3.2975 8.33301 3.06667V1.66667C8.33301 0.7475 9.08051 0 9.99967 0C10.9188 0 11.6663 0.7475 11.6663 1.66667V3.06667C11.6663 3.2975 11.4797 3.48333 11.2497 3.48333Z"
                                                    fill="white"/>
                                                <path
                                                    d="M9.99967 19.9999C8.39134 19.9999 7.08301 18.6915 7.08301 17.0832C7.08301 16.8532 7.26967 16.6665 7.49967 16.6665C7.72967 16.6665 7.91634 16.8532 7.91634 17.0832C7.91634 18.2315 8.85134 19.1665 9.99967 19.1665C11.148 19.1665 12.083 18.2315 12.083 17.0832C12.083 16.8532 12.2697 16.6665 12.4997 16.6665C12.7297 16.6665 12.9163 16.8532 12.9163 17.0832C12.9163 18.6915 11.608 19.9999 9.99967 19.9999Z"
                                                    fill="white"/>
                                                <path
                                                    d="M17.0827 17.5H2.91602C2.22685 17.5 1.66602 16.9392 1.66602 16.25C1.66602 15.8842 1.82518 15.5383 2.10352 15.3C3.41685 14.19 4.16602 12.575 4.16602 10.865V8.33333C4.16602 5.11667 6.78268 2.5 9.99935 2.5C13.216 2.5 15.8327 5.11667 15.8327 8.33333V10.865C15.8327 12.5758 16.5819 14.19 17.8885 15.2942C18.1735 15.5383 18.3327 15.8842 18.3327 16.25C18.3327 16.9392 17.7727 17.5 17.0827 17.5ZM9.99935 3.33333C7.24185 3.33333 4.99935 5.57583 4.99935 8.33333V10.865C4.99935 12.8217 4.14268 14.6675 2.64852 15.9308C2.55268 16.0125 2.49935 16.1283 2.49935 16.25C2.49935 16.48 2.68602 16.6667 2.91602 16.6667H17.0827C17.3127 16.6667 17.4994 16.48 17.4994 16.25C17.4994 16.1283 17.446 16.0125 17.3535 15.9333C15.8569 14.6675 14.9994 12.8208 14.9994 10.865V8.33333C14.9994 5.57583 12.7569 3.33333 9.99935 3.33333Z"
                                                    fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="20" height="20" fill="white"
                                                          transform="translate(-0.000488281)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        {
                                            notifications && notifications.length > 0 ?
                                                <span className='supportReplies'>{notifications.length}</span> : ''
                                        }

                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={classes.notifications}>
                                        {
                                            notifications && notifications.length > 0 ?
                                                <div className='dropdown-item'>
                                                    {
                                                        notifications.map((notification, i) =>
                                                            <div className='notifications' onClick={() => {
                                                                readNotification(notification.news_id)
                                                            }} key={i}>
                                                                <p className='notificationsName'>{notification.news_topic}</p>
                                                                <div className='d-flex justify-content-between'>
                                                                    <span
                                                                        className='notificationsDate'>{notification?.date}</span>
                                                                    <span className='notificationsDelete'
                                                                          onClick={() => {
                                                                              readNotification(notification.news_id)
                                                                          }}>{getTranslatedWord(path, 'Удалить')}</span>
                                                                </div>
                                                            </div>)
                                                    }


                                                </div>
                                                :
                                                <div className='dropdown-item'>
                                                    <p className='notifications empty'>{getTranslatedWord(path, 'Нет новых уведомлений')}</p>
                                                </div>
                                        }

                                        <div
                                            className='dropdown-item footer d-flex justify-content-between align-items-end'>
                                            <Dropdown.Item
                                                className={classes.closeNotifications}>{getTranslatedWord(path, 'Скрыть')}</Dropdown.Item>
                                            {notifications.length !== 0 ?
                                                <a className="primary-button read-all" onClick={() => {
                                                    readAllNotifications()
                                                }}>{getTranslatedWord(path, 'Смотреть все')}</a>
                                                : ''
                                            }

                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdownSupport">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0)">
                                                <path
                                                    d="M17.2276 8.26732H17.1781V7.05445C17.1781 3.15839 14.0197 0 10.1236 0C6.22751 0 3.06917 3.15839 3.06917 7.05445V8.26732H2.77216C1.68304 8.26732 0.841464 9.23265 0.841464 10.3465V13.4406C0.814032 14.5202 1.66702 15.4176 2.74662 15.4451C2.75515 15.4453 2.76363 15.4454 2.77216 15.4455H4.60383C4.86327 15.4334 5.06372 15.2131 5.0515 14.9537C5.05107 14.9443 5.05036 14.935 5.04937 14.9257V8.86135C5.04937 8.56433 4.87611 8.26728 4.60383 8.26728H4.05927V7.05441C4.05927 3.70518 6.77439 0.990053 10.1236 0.990053C13.4728 0.990053 16.188 3.70518 16.188 7.05441V8.26728H15.6434C15.3711 8.26728 15.1979 8.56429 15.1979 8.86135V14.9257C15.1703 15.184 15.3572 15.4157 15.6155 15.4433C15.6247 15.4443 15.6341 15.4451 15.6434 15.4455H16.2127L16.1632 15.5197C15.4205 16.5068 14.255 17.085 13.0196 17.0791C12.7492 15.7531 11.4549 14.8973 10.1289 15.1678C8.99489 15.399 8.17729 16.3921 8.16815 17.5494C8.1817 18.9068 9.28598 20 10.6434 19.9999C11.3154 19.989 11.9559 19.7133 12.4256 19.2326C12.7425 18.908 12.9501 18.4927 13.0196 18.0444C14.5666 18.0503 16.0255 17.3254 16.9553 16.089L17.4256 15.3959C18.4899 15.3217 19.1582 14.6534 19.1582 13.688V10.594C19.1583 9.5297 18.3662 8.26732 17.2276 8.26732ZM4.05927 14.4554H2.77216C2.23916 14.4424 1.81759 13.9999 1.83057 13.4669C1.83081 13.4581 1.83114 13.4494 1.83156 13.4405V10.3465C1.83156 9.7772 2.2276 9.25738 2.77216 9.25738H4.05927V14.4554ZM11.7078 18.5396C11.4335 18.837 11.0481 19.0073 10.6434 19.0099C9.83812 18.9971 9.18459 18.3545 9.1583 17.5495C9.15797 16.7429 9.81154 16.0888 10.6181 16.0885C11.4247 16.0882 12.0788 16.7417 12.0791 17.5483C12.0791 17.5487 12.0791 17.5491 12.0791 17.5495C12.0997 17.917 11.965 18.2763 11.7078 18.5396ZM18.1682 13.6881C18.1682 14.3564 17.5246 14.4554 17.2276 14.4554H16.188V9.25742H17.2276C17.7721 9.25742 18.1682 10.0248 18.1682 10.594V13.6881Z"
                                                    fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="20" height="20" fill="white"
                                                          transform="translate(-0.000488281)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul>
                                            <li><a className="dropdown-item" href="mailto:support@pdl-profit.com">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M11.9492 2.05078C10.6285 0.727344 8.87031 0 7 0C5.12969 0 3.37148 0.727344 2.05078 2.05078C0.727344 3.37148 0 5.12969 0 7C0 8.87031 0.727344 10.6285 2.05078 11.9492C3.37422 13.2727 5.12969 14 7 14C7.22695 14 7.41016 13.8168 7.41016 13.5898C7.41016 13.3629 7.22695 13.1797 7 13.1797C3.59297 13.1797 0.820312 10.407 0.820312 7C0.820312 3.59297 3.59297 0.820312 7 0.820312C10.407 0.820312 13.1797 3.59297 13.1797 7V8.64609C13.1797 9.32969 12.6246 9.88203 11.9437 9.88203C11.2629 9.88203 10.7078 9.32695 10.7078 8.64609V7C10.7078 4.95742 9.04531 3.29492 7.00273 3.29492C4.96016 3.29492 3.29492 4.95742 3.29492 7C3.29492 9.04258 4.95742 10.7051 7 10.7051C8.21133 10.7051 9.28867 10.1199 9.9668 9.21758C10.2156 10.0762 11.0059 10.7051 11.9437 10.7051C13.0785 10.7051 14 9.78086 14 8.64883V7C14 5.12969 13.2727 3.37148 11.9492 2.05078ZM7 9.88477C5.40859 9.88477 4.11523 8.59141 4.11523 7C4.11523 5.40859 5.40859 4.11523 7 4.11523C8.59141 4.11523 9.88477 5.40859 9.88477 7C9.88477 8.59141 8.59141 9.88477 7 9.88477Z"
                                                        fill="white"></path>
                                                </svg>
                                                support@pdl-profit.com</a>
                                            </li>
                                            <li><a className="dropdown-item" href="https://telegram.me/pdl_profit">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M0.242094 6.53129L3.66568 8.23754C3.79809 8.30287 3.95443 8.29821 4.08101 8.22412L7.12834 6.45254L5.28384 8.08704C5.18993 8.17046 5.13626 8.28946 5.13626 8.41487V12.3961C5.13626 12.8161 5.67234 12.9929 5.92376 12.6592L7.40368 10.6916L11.0635 12.7759C11.3202 12.9235 11.6492 12.7753 11.7087 12.4819L13.9913 1.10687C14.0595 0.76504 13.7194 0.486207 13.4033 0.613373L0.278261 5.73212C-0.0740725 5.86979 -0.0968225 6.36271 0.242094 6.53129V6.53129ZM12.9763 1.71937L10.9702 11.716L7.50226 9.74079C7.31326 9.63287 7.07001 9.68071 6.93584 9.85804L6.01126 11.0871V8.61146L10.9993 4.19212C11.3943 3.84271 10.9404 3.22146 10.4895 3.48629L3.84534 7.34912L1.51668 6.18887L12.9763 1.71937Z"
                                                        fill="white"></path>
                                                </svg>
                                                @pdl_profit</a></li>
                                            <li><a className="dropdown-item" href="##"
                                                   onClick={() => {handleShow(); newSupportRequest()}}>{getTranslatedWord(path, 'Отправить запрос')}</a>
                                            </li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav.Item>

                            <Nav.Item className={`${classes.navbar__account} ${classes.navbar__item}`}>
                                <div
                                    className="d-flex justify-content-between align-items-center d-md-none d-xxl-block">
                                    <a className={classes.navbar__account_cab}>{getTranslatedWord(path, 'Кабинет рекламодателя.')} {getTranslatedWord(path, 'Оффер')}: {me.login}</a>
                                    <a onClick={logout} className={classes.navbarAccountLogout}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.9668 18.3081H2.49168C2.0332 18.3081 1.66113 17.936 1.66113 17.4776V2.52736C1.66113 2.06889 2.03324 1.69682 2.49168 1.69682H9.9668C10.4261 1.69682 10.7973 1.32557 10.7973 0.866269C10.7973 0.406973 10.4261 0.0356445 9.9668 0.0356445H2.49168C1.11793 0.0356445 0 1.15361 0 2.52736V17.4775C0 18.8513 1.11793 19.9692 2.49168 19.9692H9.9668C10.4261 19.9692 10.7973 19.5979 10.7973 19.1386C10.7973 18.6793 10.4261 18.3081 9.9668 18.3081Z"
                                                fill="white"/>
                                            <path
                                                d="M19.7524 9.40886L14.7026 4.42546C14.377 4.1032 13.8504 4.10738 13.5281 4.43378C13.2059 4.76019 13.2092 5.28593 13.5365 5.6082L17.1452 9.16964H7.47499C7.01569 9.16964 6.64444 9.54089 6.64444 10.0002C6.64444 10.4595 7.01569 10.8308 7.47499 10.8308H17.1452L13.5365 14.3922C13.2092 14.7145 13.2067 15.2402 13.5281 15.5666C13.6909 15.7311 13.9052 15.8141 14.1195 15.8141C14.3305 15.8141 14.5414 15.7344 14.7026 15.5749L19.7524 10.5915C19.9102 10.4354 19.9999 10.2227 19.9999 10.0002C19.9999 9.77765 19.911 9.56585 19.7524 9.40886Z"
                                                fill="white"/>
                                        </svg>
                                    </a>
                                </div>
                                <Dropdown className='d-none d-md-block d-xxl-none'>
                                    <Dropdown.Toggle>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M20 10C20 4.47311 15.5275 0 10 0C4.47311 0 0 4.4725 0 10C0 15.4779 4.43512 20 10 20C15.542 20 20 15.5031 20 10ZM10 1.17188C14.8679 1.17188 18.8281 5.13214 18.8281 10C18.8281 11.7807 18.2985 13.4923 17.3163 14.942C13.3722 10.6995 6.63498 10.6918 2.68372 14.942C1.70151 13.4923 1.17188 11.7807 1.17188 10C1.17188 5.13214 5.13214 1.17188 10 1.17188ZM3.41461 15.8789C6.92139 11.9455 13.0795 11.9464 16.5852 15.8789C13.0667 19.8154 6.93466 19.8166 3.41461 15.8789Z"
                                                fill="white"/>
                                            <path
                                                d="M9.99986 10.9543C11.8903 10.9543 13.4284 9.63594 13.4284 8.01553V7.03594C13.4284 5.41554 11.8903 4.09717 9.99986 4.09717C8.10938 4.09717 6.57129 5.41554 6.57129 7.03594V8.01553C6.57129 9.63594 8.10938 10.9543 9.99986 10.9543ZM7.71415 7.03594C7.71415 5.95559 8.73944 5.07676 9.99986 5.07676C11.2603 5.07676 12.2856 5.95559 12.2856 7.03594V8.01553C12.2856 9.09589 11.2603 9.97472 9.99986 9.97472C8.73944 9.97472 7.71415 9.09589 7.71415 8.01553V7.03594Z"
                                                fill="white"/>
                                        </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul>
                                            <li><a className="dropdown-item" href="#">{userName || ''}</a>

                                            </li>
                                            {/*<li><a className="dropdown-item" href="##">Перейти в Мой аккаунт</a>*/}
                                            {/*</li>*/}
                                            <li><a className="dropdown-item" onClick={logout} href="#">Выйти</a>
                                            </li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav.Item>

                            <Nav.Item className={`${classes.language__menu} d-none d-md-block`}>
                                <Dropdown>
                                    <Dropdown.Toggle className={classes.languageMenuButton}>
                                        <span>{lang.toUpperCase()}</span>
                                        <span className={classes.language__icon}>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0)">
                                                <path
                                                    d="M5.99997 9.42041C5.7849 9.42041 5.56987 9.33829 5.4059 9.17441L0.24617 4.01463C-0.0820565 3.6864 -0.0820565 3.15424 0.24617 2.82615C0.574263 2.49805 1.10632 2.49805 1.43457 2.82615L5.99997 7.39181L10.5654 2.82631C10.8936 2.49821 11.4256 2.49821 11.7537 2.82631C12.0821 3.1544 12.0821 3.68656 11.7537 4.01479L6.59404 9.17457C6.42999 9.33848 6.21495 9.42041 5.99997 9.42041Z"
                                                    fill="white"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0">
                                                <rect width="12" height="12" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul onClick={setLangTranslation}>
                                            {allLang.length > 0 ?
                                                allLang.map((l, i) =>
                                                    l !== lang ?
                                                        <li key={i}>
                                                            <Dropdown.Item className="dropdown-item"
                                                                           href="#">{l.toUpperCase()}</Dropdown.Item>
                                                        </li>
                                                        : ''
                                                ) : ''
                                            }
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav.Item>

                            <Nav.Item className={`${classes.navbar__item} d-md-none`}>
                                <ul className={classes.navbar__item_list}>
                                    <li>
                                        <NavLink to={`${pid ? `/${pid}` : '/'}`} onClick={() => {setExpanded(false); clearOffers()}}>
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M29.2146 2.80204C28.663 2.21552 27.888 1.88037 27.078 1.88037C25.4651 1.88037 24.1594 3.18607 24.1594 4.79899C24.1594 5.05734 24.1943 5.31568 24.2641 5.56705L21.1011 7.80838C19.97 6.66328 18.1197 6.64931 16.9746 7.78045C16.1646 8.58342 15.8923 9.7774 16.2833 10.8527L13.3647 13.3105C12.443 11.9908 10.6276 11.6626 9.30097 12.5843C8.79825 12.9334 8.4212 13.4362 8.21871 14.0087L5.79584 13.0242C5.52352 11.4322 4.02232 10.3639 2.43035 10.6362C0.83837 10.9085 -0.229928 12.4097 0.0423832 14.0017C0.314695 15.5937 1.8159 16.662 3.40787 16.3897C4.45523 16.2151 5.32104 15.482 5.67714 14.4835L8.10001 15.468C8.37232 17.06 9.87353 18.1283 11.4655 17.856C12.869 17.6186 13.8954 16.4036 13.8954 14.9792C13.8954 14.8885 13.8884 14.7907 13.8814 14.7L17.0653 12.0118C18.2593 13.094 20.1027 13.0102 21.1849 11.8162C21.8762 11.0552 22.1136 9.98687 21.8203 9.00935L24.9484 6.78896C26.0516 7.96898 27.895 8.02483 29.075 6.92861C30.255 5.8254 30.3109 3.98206 29.2146 2.80204ZM2.91911 15.0421C2.08123 15.0421 1.39696 14.3578 1.39696 13.5199C1.39696 12.6821 2.08123 11.9978 2.91911 11.9978C3.75699 11.9978 4.44126 12.6821 4.44126 13.5199C4.44126 14.3578 3.75699 15.0421 2.91911 15.0421ZM10.9698 16.5084C10.1319 16.5084 9.4476 15.8241 9.4476 14.9862C9.4476 14.1484 10.1319 13.4641 10.9698 13.4641C11.8076 13.4641 12.4919 14.1484 12.4919 14.9862C12.4919 15.8241 11.8146 16.5084 10.9698 16.5084ZM19.0274 11.3833C18.1895 11.3833 17.5052 10.6991 17.5052 9.86119C17.5052 9.02331 18.1895 8.33904 19.0274 8.33904C19.8653 8.33904 20.5495 9.02331 20.5495 9.86119C20.5495 10.6991 19.8653 11.3833 19.0274 11.3833ZM27.078 6.32114C26.2401 6.32114 25.5559 5.63687 25.5559 4.79899C25.5559 3.96111 26.2401 3.27684 27.078 3.27684C27.9159 3.27684 28.6002 3.96111 28.6002 4.79899C28.6002 5.64385 27.9229 6.32114 27.078 6.32114Z"
                                                    fill="#fff"/>
                                                <path
                                                    d="M29.0331 10.0358H25.123C24.7389 10.0358 24.4247 10.35 24.4247 10.734V27.352C24.4247 27.736 24.7389 28.0502 25.123 28.0502H29.0331C29.4171 28.0502 29.7313 27.736 29.7313 27.352V10.734C29.7313 10.35 29.4171 10.0358 29.0331 10.0358ZM28.3349 26.7235H25.8212V11.4322H28.3349V26.7235Z"
                                                    fill="#fff"/>
                                                <path
                                                    d="M21.0034 15.0631H17.0933C16.7092 15.0631 16.395 15.3773 16.395 15.7613V27.352C16.395 27.736 16.7092 28.0502 17.0933 28.0502H21.0034C21.3874 28.0502 21.7016 27.736 21.7016 27.352V15.7613C21.7016 15.3773 21.3874 15.0631 21.0034 15.0631ZM20.3052 26.7236H17.7915V16.4595H20.3052V26.7236Z"
                                                    fill="#fff"/>
                                                <path
                                                    d="M12.9039 20.2998H8.99376C8.60973 20.2998 8.29552 20.614 8.29552 20.998V27.4218C8.29552 27.8058 8.60973 28.12 8.99376 28.12H12.9039C13.2879 28.12 13.6021 27.8058 13.6021 27.4218V20.998C13.6021 20.614 13.2879 20.2998 12.9039 20.2998ZM12.2056 26.7236H9.69199V21.6963H12.2056V26.7236Z"
                                                    fill="#fff"/>
                                                <path
                                                    d="M4.87416 18.8335H0.964051C0.580022 18.8335 0.265816 19.1477 0.265816 19.5317V27.4218C0.265816 27.8058 0.580022 28.12 0.964051 28.12H4.87416C5.25819 28.12 5.5724 27.8058 5.5724 27.4218V19.5317C5.5724 19.1477 5.25819 18.8335 4.87416 18.8335ZM4.17593 26.7236H1.66229V20.23H4.17593V26.7236Z"
                                                    fill="#fff"/>
                                            </svg>
                                            {getTranslatedWord(path, 'Статистика')}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/webs/1" onClick={() => setExpanded(false)}>
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M30 15C30 6.70967 23.2912 0 15 0C6.70967 0 0 6.70876 0 15C0 23.2169 6.65268 30 15 30C23.313 30 30 23.2546 30 15ZM15 1.75781C22.3018 1.75781 28.2422 7.69821 28.2422 15C28.2422 17.6711 27.4477 20.2384 25.9744 22.413C20.0583 16.0492 9.95247 16.0378 4.02557 22.413C2.55226 20.2384 1.75781 17.6711 1.75781 15C1.75781 7.69821 7.69821 1.75781 15 1.75781ZM5.12192 23.8184C10.3821 17.9182 19.6193 17.9196 24.8779 23.8184C19.6001 29.7231 10.402 29.7249 5.12192 23.8184Z"
                                                    fill="#fff"/>
                                                <path
                                                    d="M15 16.4314C17.8357 16.4314 20.1429 14.4538 20.1429 12.0232V10.5538C20.1429 8.1232 17.8357 6.14565 15 6.14565C12.1643 6.14565 9.85714 8.1232 9.85714 10.5538V12.0232C9.85714 14.4538 12.1643 16.4314 15 16.4314ZM11.5714 10.5538C11.5714 8.93327 13.1094 7.61503 15 7.61503C16.8906 7.61503 18.4286 8.93327 18.4286 10.5538V12.0232C18.4286 13.6437 16.8906 14.962 15 14.962C13.1094 14.962 11.5714 13.6437 11.5714 12.0232V10.5538Z"
                                                    fill="#fff"/>
                                            </svg>
                                            {getTranslatedWord(path, 'Вебы')}
                                        </NavLink>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <NavLink to="/documents">*/}
                                    {/*        <svg width="30" height="30" viewBox="0 0 30 30" fill="none"*/}
                                    {/*             xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*            <g clipPath="url(#clip0)">*/}
                                    {/*                <path*/}
                                    {/*                    d="M25.4179 6.22664L19.7681 0.881602C19.1671 0.313066 18.3806 0 17.5533 0H6.79688C5.0199 0 3.57422 1.44568 3.57422 3.22266V26.7773C3.57422 28.5543 5.0199 30 6.79688 30H23.2031C24.9801 30 26.4258 28.5543 26.4258 26.7773V8.5677C26.4258 7.68592 26.0584 6.83262 25.4179 6.22664ZM23.7106 7.03125H19.3359C19.1744 7.03125 19.043 6.89982 19.043 6.73828V2.61539L23.7106 7.03125ZM23.2031 28.2422H6.79688C5.98916 28.2422 5.33203 27.5851 5.33203 26.7773V3.22266C5.33203 2.41494 5.98916 1.75781 6.79688 1.75781H17.2852V6.73828C17.2852 7.86908 18.2051 8.78906 19.3359 8.78906H24.668V26.7773C24.668 27.5851 24.0108 28.2422 23.2031 28.2422Z"*/}
                                    {/*                    fill="#fff"/>*/}
                                    {/*                <path*/}
                                    {/*                    d="M21.2695 11.7188H8.37891C7.89352 11.7188 7.5 12.1123 7.5 12.5977C7.5 13.083 7.89352 13.4766 8.37891 13.4766H21.2695C21.7549 13.4766 22.1484 13.083 22.1484 12.5977C22.1484 12.1123 21.7549 11.7188 21.2695 11.7188Z"*/}
                                    {/*                    fill="#fff"/>*/}
                                    {/*                <path*/}
                                    {/*                    d="M21.2695 16.4062H8.37891C7.89352 16.4062 7.5 16.7998 7.5 17.2852C7.5 17.7705 7.89352 18.1641 8.37891 18.1641H21.2695C21.7549 18.1641 22.1484 17.7705 22.1484 17.2852C22.1484 16.7998 21.7549 16.4062 21.2695 16.4062Z"*/}
                                    {/*                    fill="#fff"/>*/}
                                    {/*                <path*/}
                                    {/*                    d="M12.6398 21.0938H8.37891C7.89352 21.0938 7.5 21.4873 7.5 21.9727C7.5 22.458 7.89352 22.8516 8.37891 22.8516H12.6398C13.1252 22.8516 13.5188 22.458 13.5188 21.9727C13.5188 21.4873 13.1252 21.0938 12.6398 21.0938Z"*/}
                                    {/*                    fill="#fff"/>*/}
                                    {/*            </g>*/}
                                    {/*            <defs>*/}
                                    {/*                <clipPath id="clip0">*/}
                                    {/*                    <rect width="30" height="30" fill="white"/>*/}
                                    {/*                </clipPath>*/}
                                    {/*            </defs>*/}
                                    {/*        </svg>*/}
                                    {/*        {getTranslatedWord(path,'Документы')}*/}
                                    {/*    </NavLink>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*    <NavLink to="/banners">*/}
                                    {/*        <svg width="30" height="30" viewBox="0 0 30 30" fill="none"*/}
                                    {/*             xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*            <path*/}
                                    {/*                d="M6.15234 3.57422C4.69846 3.57422 3.51562 4.75705 3.51562 6.21094V9.72656C3.51562 10.212 3.90914 10.6055 4.39453 10.6055C4.87992 10.6055 5.27344 10.212 5.27344 9.72656V8.84766H7.03125V9.72656C7.03125 10.212 7.42477 10.6055 7.91016 10.6055C8.39555 10.6055 8.78906 10.212 8.78906 9.72656V6.21094C8.78906 4.75705 7.60623 3.57422 6.15234 3.57422ZM5.27344 7.08984V6.21094C5.27344 5.72631 5.66771 5.33203 6.15234 5.33203C6.63697 5.33203 7.03125 5.72631 7.03125 6.21094V7.08984H5.27344Z"*/}
                                    {/*                fill="#fff"/>*/}
                                    {/*            <path*/}
                                    {/*                d="M12.3047 3.57422H11.4258C10.9404 3.57422 10.5469 3.96773 10.5469 4.45312V9.72656C10.5469 10.212 10.9404 10.6055 11.4258 10.6055H12.3047C14.2432 10.6055 15.8203 9.02836 15.8203 7.08984C15.8203 5.15133 14.2432 3.57422 12.3047 3.57422ZM12.3047 8.84766V5.33203C13.2739 5.33203 14.0625 6.12059 14.0625 7.08984C14.0625 8.0591 13.2739 8.84766 12.3047 8.84766Z"*/}
                                    {/*                fill="#fff"/>*/}
                                    {/*            <path*/}
                                    {/*                d="M29.1211 14.1211C29.6065 14.1211 30 13.7276 30 13.2422V0.9375C30 0.452109 29.6065 0.0585938 29.1211 0.0585938H0.878906C0.393516 0.0585938 0 0.452109 0 0.9375V13.2422C0 13.7276 0.393516 14.1211 0.878906 14.1211H15.5805L16.0317 15.8789H13.1836C12.6982 15.8789 12.3047 16.2724 12.3047 16.7578V29.0625C12.3047 29.5479 12.6982 29.9414 13.1836 29.9414H29.1211C29.6065 29.9414 30 29.5479 30 29.0625V16.7578C30 16.2724 29.6065 15.8789 29.1211 15.8789H25.7323L22.1381 14.1211H29.1211ZM1.75781 12.3633V1.81641H28.2422V12.3633H18.544L16.3503 11.2904C15.6782 10.9617 14.9268 11.5733 15.1128 12.2984L15.1294 12.3632L1.75781 12.3633ZM28.2422 28.1836H14.0625V17.6367H16.4828L17.7938 22.7454C17.9544 23.3709 18.7143 23.6155 19.2096 23.2007L20.4602 22.1529L21.7924 23.7499C22.1033 24.1226 22.659 24.1734 23.0323 23.8602L26.0631 21.317C26.4342 21.0056 26.4833 20.4527 26.173 20.0807L24.8385 18.4809L25.8461 17.6367H28.2422V28.1836ZM22.9267 18.9334L24.2617 20.5338L22.5775 21.9471L21.2457 20.3506C20.9337 19.9767 20.378 19.9284 20.0064 20.2399L19.1497 20.9576L17.2888 13.7062L23.9258 16.9522L23.0371 17.6967C22.6657 18.0079 22.6162 18.5612 22.9267 18.9334Z"*/}
                                    {/*                fill="#fff"/>*/}
                                    {/*            <path*/}
                                    {/*                d="M9.66797 15.8789H0.878906C0.393516 15.8789 0 16.2724 0 16.7578V29.0625C0 29.5479 0.393516 29.9414 0.878906 29.9414H9.66797C10.1534 29.9414 10.5469 29.5479 10.5469 29.0625V16.7578C10.5469 16.2724 10.1534 15.8789 9.66797 15.8789ZM8.78906 28.1836H1.75781V17.6367H8.78906V28.1836Z"*/}
                                    {/*                fill="#fff"/>*/}
                                    {/*            <path*/}
                                    {/*                d="M18.457 6.21094H25.4883C25.9737 6.21094 26.3672 5.81742 26.3672 5.33203C26.3672 4.84664 25.9737 4.45312 25.4883 4.45312H18.457C17.9716 4.45312 17.5781 4.84664 17.5781 5.33203C17.5781 5.81742 17.9716 6.21094 18.457 6.21094Z"*/}
                                    {/*                fill="#fff"/>*/}
                                    {/*            <path*/}
                                    {/*                d="M18.457 9.72656H25.4883C25.9737 9.72656 26.3672 9.33305 26.3672 8.84766C26.3672 8.36227 25.9737 7.96875 25.4883 7.96875H18.457C17.9716 7.96875 17.5781 8.36227 17.5781 8.84766C17.5781 9.33305 17.9716 9.72656 18.457 9.72656Z"*/}
                                    {/*                fill="#fff"/>*/}
                                    {/*        </svg>*/}
                                    {/*        {getTranslatedWord(path,'Баннеры')}*/}
                                    {/*    </NavLink>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*    <NavLink to="/history">*/}
                                    {/*        <svg width="30" height="30" viewBox="0 0 30 30" fill="none"*/}
                                    {/*             xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*            <path*/}
                                    {/*                d="M20.3446 17.6488L16.1617 14.5117V8.12139C16.1617 7.47885 15.6423 6.95947 14.9998 6.95947C14.3573 6.95947 13.8379 7.47885 13.8379 8.12139V15.0928C13.8379 15.4587 14.0099 15.8038 14.3027 16.0223L18.9502 19.508C19.1593 19.6648 19.4034 19.7404 19.6462 19.7404C20.0006 19.7404 20.3491 19.5812 20.5769 19.2744C20.9627 18.762 20.8581 18.0335 20.3446 17.6488Z"*/}
                                    {/*                fill="#fff"/>*/}
                                    {/*            <path*/}
                                    {/*                d="M15 0C6.7285 0 0 6.7285 0 15C0 23.2715 6.7285 30 15 30C23.2715 30 30 23.2715 30 15C30 6.7285 23.2715 0 15 0ZM15 27.6762C8.01123 27.6762 2.32377 21.9888 2.32377 15C2.32377 8.01123 8.01123 2.32377 15 2.32377C21.9899 2.32377 27.6762 8.01123 27.6762 15C27.6762 21.9888 21.9888 27.6762 15 27.6762Z"*/}
                                    {/*                fill="#fff"/>*/}
                                    {/*        </svg>*/}
                                    {/*        {getTranslatedWord(path,'История ставок')}*/}
                                    {/*    </NavLink>*/}
                                    {/*</li>*/}
                                </ul>
                            </Nav.Item>

                            <Nav.Item className={`${classes.navbar__item} d-md-none`}>
                                <ul className={classes.navbar__item_list}>
                                    <li>
                                        <a onClick={() => toggleNotification()}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip_head)">
                                                    <path
                                                        d="M11.2502 3.48333C11.0202 3.48333 10.8335 3.29667 10.8335 3.06667V1.66667C10.8335 1.2075 10.4593 0.833333 10.0002 0.833333C9.54099 0.833333 9.16683 1.2075 9.16683 1.66667V3.06667C9.16683 3.29667 8.98016 3.48333 8.75016 3.48333C8.52016 3.48333 8.3335 3.2975 8.3335 3.06667V1.66667C8.3335 0.7475 9.08099 0 10.0002 0C10.9193 0 11.6668 0.7475 11.6668 1.66667V3.06667C11.6668 3.2975 11.4802 3.48333 11.2502 3.48333Z"
                                                        fill="white"/>
                                                    <path
                                                        d="M10.0002 19.9994C8.39183 19.9994 7.0835 18.691 7.0835 17.0827C7.0835 16.8527 7.27016 16.666 7.50016 16.666C7.73016 16.666 7.91683 16.8527 7.91683 17.0827C7.91683 18.231 8.85183 19.166 10.0002 19.166C11.1485 19.166 12.0835 18.231 12.0835 17.0827C12.0835 16.8527 12.2702 16.666 12.5002 16.666C12.7302 16.666 12.9168 16.8527 12.9168 17.0827C12.9168 18.691 11.6085 19.9994 10.0002 19.9994Z"
                                                        fill="white"/>
                                                    <path
                                                        d="M17.0832 17.5H2.9165C2.22734 17.5 1.6665 16.9392 1.6665 16.25C1.6665 15.8842 1.82567 15.5383 2.104 15.3C3.41734 14.19 4.1665 12.575 4.1665 10.865V8.33333C4.1665 5.11667 6.78317 2.5 9.99984 2.5C13.2165 2.5 15.8332 5.11667 15.8332 8.33333V10.865C15.8332 12.5758 16.5823 14.19 17.889 15.2942C18.174 15.5383 18.3332 15.8842 18.3332 16.25C18.3332 16.9392 17.7732 17.5 17.0832 17.5ZM9.99984 3.33333C7.24234 3.33333 4.99984 5.57583 4.99984 8.33333V10.865C4.99984 12.8217 4.14317 14.6675 2.649 15.9308C2.55317 16.0125 2.49984 16.1283 2.49984 16.25C2.49984 16.48 2.6865 16.6667 2.9165 16.6667H17.0832C17.3132 16.6667 17.4998 16.48 17.4998 16.25C17.4998 16.1283 17.4465 16.0125 17.354 15.9333C15.8573 14.6675 14.9998 12.8208 14.9998 10.865V8.33333C14.9998 5.57583 12.7573 3.33333 9.99984 3.33333Z"
                                                        fill="white"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip_head">
                                                        <rect width="20" height="20" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>

                                            <span className='notification-word'>
                                                {getTranslatedWord(path, 'Уведомления')}
                                                {
                                                    notifications && notifications.length > 0 ?
                                                        <span
                                                            className='supportReplies'>{notifications.length}</span> : ''
                                                }
                                            </span>

                                        </a>


                                        {
                                            notifications && notifications.length > 0 ?
                                                <div
                                                    className={`notifications-mob ${toggleNotifications ? 'toggled' : ''}`}>
                                                    {
                                                        notifications.map((notification, i) =>
                                                            <div className='notifications' onClick={() => {
                                                                readNotification(notification.news_id)
                                                            }} key={i}>
                                                                <p className='notificationsName'>{notification.news_topic}</p>
                                                                <div className='d-flex justify-content-between'>
                                                                    <span
                                                                        className='notificationsDate'>{notification?.date}</span>
                                                                    <span className='notificationsDelete'
                                                                          onClick={() => {
                                                                              readNotification(notification.news_id)
                                                                          }}>{getTranslatedWord(path, 'Удалить')}</span>
                                                                </div>
                                                            </div>)}
                                                </div> : ''
                                        }

                                    </li>
                                </ul>
                            </Nav.Item>

                            <Nav.Item className={`${classes.navbar__item} d-md-none`}>
                                <ul className={classes.navbar__item_list}>
                                    <li>
                                        <a aria-controls="navbarNav" href="#" onClick={() => {handleShow(); setExpanded(false); newSupportRequest()}}>

                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0)">
                                                        <path
                                                            d="M17.2276 8.26732H17.1781V7.05445C17.1781 3.15839 14.0197 0 10.1236 0C6.22751 0 3.06917 3.15839 3.06917 7.05445V8.26732H2.77216C1.68304 8.26732 0.841464 9.23265 0.841464 10.3465V13.4406C0.814032 14.5202 1.66702 15.4176 2.74662 15.4451C2.75515 15.4453 2.76363 15.4454 2.77216 15.4455H4.60383C4.86327 15.4334 5.06372 15.2131 5.0515 14.9537C5.05107 14.9443 5.05036 14.935 5.04937 14.9257V8.86135C5.04937 8.56433 4.87611 8.26728 4.60383 8.26728H4.05927V7.05441C4.05927 3.70518 6.77439 0.990053 10.1236 0.990053C13.4728 0.990053 16.188 3.70518 16.188 7.05441V8.26728H15.6434C15.3711 8.26728 15.1979 8.56429 15.1979 8.86135V14.9257C15.1703 15.184 15.3572 15.4157 15.6155 15.4433C15.6247 15.4443 15.6341 15.4451 15.6434 15.4455H16.2127L16.1632 15.5197C15.4205 16.5068 14.255 17.085 13.0196 17.0791C12.7492 15.7531 11.4549 14.8973 10.1289 15.1678C8.99489 15.399 8.17729 16.3921 8.16815 17.5494C8.1817 18.9068 9.28598 20 10.6434 19.9999C11.3154 19.989 11.9559 19.7133 12.4256 19.2326C12.7425 18.908 12.9501 18.4927 13.0196 18.0444C14.5666 18.0503 16.0255 17.3254 16.9553 16.089L17.4256 15.3959C18.4899 15.3217 19.1582 14.6534 19.1582 13.688V10.594C19.1583 9.5297 18.3662 8.26732 17.2276 8.26732ZM4.05927 14.4554H2.77216C2.23916 14.4424 1.81759 13.9999 1.83057 13.4669C1.83081 13.4581 1.83114 13.4494 1.83156 13.4405V10.3465C1.83156 9.7772 2.2276 9.25738 2.77216 9.25738H4.05927V14.4554ZM11.7078 18.5396C11.4335 18.837 11.0481 19.0073 10.6434 19.0099C9.83812 18.9971 9.18459 18.3545 9.1583 17.5495C9.15797 16.7429 9.81154 16.0888 10.6181 16.0885C11.4247 16.0882 12.0788 16.7417 12.0791 17.5483C12.0791 17.5487 12.0791 17.5491 12.0791 17.5495C12.0997 17.917 11.965 18.2763 11.7078 18.5396ZM18.1682 13.6881C18.1682 14.3564 17.5246 14.4554 17.2276 14.4554H16.188V9.25742H17.2276C17.7721 9.25742 18.1682 10.0248 18.1682 10.594V13.6881Z"
                                                            fill="white"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0">
                                                            <rect width="20" height="20" fill="white"
                                                                  transform="translate(-0.000488281)"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            {getTranslatedWord(path, 'Служба поддержки')}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:support@pdl-profit.com">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11.9492 2.05078C10.6285 0.727344 8.87031 0 7 0C5.12969 0 3.37148 0.727344 2.05078 2.05078C0.727344 3.37148 0 5.12969 0 7C0 8.87031 0.727344 10.6285 2.05078 11.9492C3.37422 13.2727 5.12969 14 7 14C7.22695 14 7.41016 13.8168 7.41016 13.5898C7.41016 13.3629 7.22695 13.1797 7 13.1797C3.59297 13.1797 0.820312 10.407 0.820312 7C0.820312 3.59297 3.59297 0.820312 7 0.820312C10.407 0.820312 13.1797 3.59297 13.1797 7V8.64609C13.1797 9.32969 12.6246 9.88203 11.9437 9.88203C11.2629 9.88203 10.7078 9.32695 10.7078 8.64609V7C10.7078 4.95742 9.04531 3.29492 7.00273 3.29492C4.96016 3.29492 3.29492 4.95742 3.29492 7C3.29492 9.04258 4.95742 10.7051 7 10.7051C8.21133 10.7051 9.28867 10.1199 9.9668 9.21758C10.2156 10.0762 11.0059 10.7051 11.9437 10.7051C13.0785 10.7051 14 9.78086 14 8.64883V7C14 5.12969 13.2727 3.37148 11.9492 2.05078ZM7 9.88477C5.40859 9.88477 4.11523 8.59141 4.11523 7C4.11523 5.40859 5.40859 4.11523 7 4.11523C8.59141 4.11523 9.88477 5.40859 9.88477 7C9.88477 8.59141 8.59141 9.88477 7 9.88477Z"
                                                    fill="white"></path>
                                            </svg>
                                            support@pdl-profit.com
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://telegram.me/pdl_profit">
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.242094 6.53129L3.66568 8.23754C3.79809 8.30287 3.95443 8.29821 4.08101 8.22412L7.12834 6.45254L5.28384 8.08704C5.18993 8.17046 5.13626 8.28946 5.13626 8.41487V12.3961C5.13626 12.8161 5.67234 12.9929 5.92376 12.6592L7.40368 10.6916L11.0635 12.7759C11.3202 12.9235 11.6492 12.7753 11.7087 12.4819L13.9913 1.10687C14.0595 0.76504 13.7194 0.486207 13.4033 0.613373L0.278261 5.73212C-0.0740725 5.86979 -0.0968225 6.36271 0.242094 6.53129V6.53129ZM12.9763 1.71937L10.9702 11.716L7.50226 9.74079C7.31326 9.63287 7.07001 9.68071 6.93584 9.85804L6.01126 11.0871V8.61146L10.9993 4.19212C11.3943 3.84271 10.9404 3.22146 10.4895 3.48629L3.84534 7.34912L1.51668 6.18887L12.9763 1.71937Z"
                                                    fill="white"></path>
                                            </svg>
                                            @pdl_profit
                                        </a>
                                    </li>
                                </ul>
                            </Nav.Item>

                            <Nav.Item className={`${classes.navbar__item} d-md-none`}>
                                <ul className={classes.navbar__item_list}>
                                    <li>
                                        <a target='_blank' href="https://pdl-profit.com/rules.html">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M13.8096 0H5.26636C5.13782 0 5.01465 0.0510254 4.92371 0.141968L1.89197 3.17371C1.80103 3.26453 1.75 3.38782 1.75 3.51636V15.5154C1.75 15.7831 1.96692 16 2.23462 16H13.8096C14.0771 16 14.2942 15.7831 14.2942 15.5154V0.484619C14.2942 0.216919 14.0771 0 13.8096 0V0ZM4.78174 1.65454V3.03174H3.40454L4.78174 1.65454ZM13.325 15.0308H2.71924V4.00098H5.26636C5.53394 4.00098 5.75098 3.78394 5.75098 3.51636V0.969238H13.325V15.0308Z"
                                                    fill="white"></path>
                                                <path
                                                    d="M5.43384 5.19531C5.16626 5.19531 4.94922 5.41235 4.94922 5.67993C4.94922 5.94763 5.16626 6.16455 5.43384 6.16455H10.6123C10.8799 6.16455 11.0969 5.94763 11.0969 5.67993C11.0969 5.41235 10.8799 5.19531 10.6123 5.19531H5.43384Z"
                                                    fill="white"></path>
                                                <path
                                                    d="M10.6123 7.00195H5.43384C5.16626 7.00195 4.94922 7.21899 4.94922 7.48657C4.94922 7.75427 5.16626 7.97119 5.43384 7.97119H10.6123C10.8799 7.97119 11.0969 7.75427 11.0969 7.48657C11.0969 7.21899 10.8799 7.00195 10.6123 7.00195Z"
                                                    fill="white"></path>
                                                <path
                                                    d="M10.6123 8.80859H5.43384C5.16626 8.80859 4.94922 9.02551 4.94922 9.29309C4.94922 9.56079 5.16626 9.77771 5.43384 9.77771H10.6123C10.8799 9.77771 11.0969 9.56079 11.0969 9.29309C11.0969 9.02551 10.8799 8.80859 10.6123 8.80859Z"
                                                    fill="white"></path>
                                                <path
                                                    d="M9.33618 10.6133H5.43384C5.16626 10.6133 4.94922 10.8302 4.94922 11.0979C4.94922 11.3655 5.16626 11.5824 5.43384 11.5824H9.33618C9.60376 11.5824 9.8208 11.3655 9.8208 11.0979C9.8208 10.8302 9.60376 10.6133 9.33618 10.6133Z"
                                                    fill="white"></path>
                                            </svg>
                                            {getTranslatedWord(path, 'Правила партнерской программы')}
                                        </a>
                                    </li>
                                </ul>
                            </Nav.Item>

                            <Nav.Item className={`${classes.navbar__item} d-md-none`}>
                                <div onClick={setLangTranslation}>
                                    <span className='current-lang'>{lang.toUpperCase()}</span> {allLang.length > 0 ?
                                        allLang.map((l, i) =>
                                            l !== lang ?
                                                <a key={i} className='lang-item'>
                                                    {l.toUpperCase()}
                                                </a>
                                                : ''
                                        ) : ''
                                    }
                                </div>
                            </Nav.Item>

                            <Nav.Item className={`${classes.navbar__item} d-md-none`}>
                                <div className={classes.navbar__manager}>
                                    { me?.manager ? <>
                                    <span className={classes.navbar__manager_title}>Ваш менеджер</span>

                                        <div className='d-flex'>
                                            <div className={classes.photo}>
                                                <img src={manager27194} alt="manager"/>
                                            </div>
                                            <div>
                                                <div className={classes.manager__name}>
                                                    {me?.manager?.name}
                                                </div>
                                                <div className={classes.manager__links}>
                                                    <a href={`mailto:${me?.manager?.email}`}>
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M11.9492 2.05078C10.6285 0.727344 8.87031 0 7 0C5.12969 0 3.37148 0.727344 2.05078 2.05078C0.727344 3.37148 0 5.12969 0 7C0 8.87031 0.727344 10.6285 2.05078 11.9492C3.37422 13.2727 5.12969 14 7 14C7.22695 14 7.41016 13.8168 7.41016 13.5898C7.41016 13.3629 7.22695 13.1797 7 13.1797C3.59297 13.1797 0.820312 10.407 0.820312 7C0.820312 3.59297 3.59297 0.820312 7 0.820312C10.407 0.820312 13.1797 3.59297 13.1797 7V8.64609C13.1797 9.32969 12.6246 9.88203 11.9437 9.88203C11.2629 9.88203 10.7078 9.32695 10.7078 8.64609V7C10.7078 4.95742 9.04531 3.29492 7.00273 3.29492C4.96016 3.29492 3.29492 4.95742 3.29492 7C3.29492 9.04258 4.95742 10.7051 7 10.7051C8.21133 10.7051 9.28867 10.1199 9.9668 9.21758C10.2156 10.0762 11.0059 10.7051 11.9437 10.7051C13.0785 10.7051 14 9.78086 14 8.64883V7C14 5.12969 13.2727 3.37148 11.9492 2.05078ZM7 9.88477C5.40859 9.88477 4.11523 8.59141 4.11523 7C4.11523 5.40859 5.40859 4.11523 7 4.11523C8.59141 4.11523 9.88477 5.40859 9.88477 7C9.88477 8.59141 8.59141 9.88477 7 9.88477Z"
                                                                fill="#fff"/>
                                                        </svg>
                                                    </a>
                                                    {
                                                        me?.manager?.contact && me?.manager?.contact.length !== 0 ?
                                                            <a href={(me?.manager?.contact[0] === 'telegram') ? `https://telegram.me/${me?.manager?.contact[1]}` : ''}>
                                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0)">
                                                                        <path
                                                                            d="M0.242094 6.53129L3.66568 8.23754C3.79809 8.30287 3.95443 8.29821 4.08101 8.22412L7.12834 6.45254L5.28384 8.08704C5.18993 8.17046 5.13626 8.28946 5.13626 8.41487V12.3961C5.13626 12.8161 5.67234 12.9929 5.92376 12.6592L7.40368 10.6916L11.0635 12.7759C11.3202 12.9235 11.6492 12.7753 11.7087 12.4819L13.9913 1.10687C14.0595 0.76504 13.7194 0.486207 13.4033 0.613373L0.278261 5.73212C-0.0740725 5.86979 -0.0968225 6.36271 0.242094 6.53129ZM12.9763 1.71937L10.9702 11.716L7.50226 9.74079C7.31326 9.63287 7.07001 9.68071 6.93584 9.85804L6.01126 11.0871V8.61146L10.9993 4.19212C11.3943 3.84271 10.9404 3.22146 10.4895 3.48629L3.84534 7.34912L1.51668 6.18887L12.9763 1.71937Z"
                                                                            fill="#fff"/>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0">
                                                                            <rect width="14" height="14" fill="#fff"/>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </a>
                                                            :
                                                            ''
                                                    }
                                                </div>
                                            </div>
                                        </div> </> : 'No manager'}

                                </div>
                            </Nav.Item>
                        </div>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </header>

        <ModalSupport show={show} onHide={() => handleClose(false)}/>

    </>)
}

export default Header;