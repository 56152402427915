import React from 'react'
import ReactPaginate from "react-paginate";

const Pagination = (props) => {
    return (
            <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={props.onPageChange}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={props.pageCount}
                previousLabel=""
                forcePage={props.paginateNum - 1}
            />
    )
};

export default Pagination;