import React from 'react';
import {NavLink, useParams} from "react-router-dom";
import classes from './Sidebar.module.css'
import {useSelector, useDispatch} from "react-redux";
import {ARE_OFFERS_LOADED, CLEAR_OFFERS, IS_PRELOADER_ACTIVE} from "../../store/types";


const Sidebar = (props) => {
    const translation = useSelector(state => state.data.translation)
    const dispatch = useDispatch()
    const {pid} = useParams();

    const clearOffers = () => {
        dispatch({type: CLEAR_OFFERS})
        dispatch({
            type: IS_PRELOADER_ACTIVE,
            payload: true
        })
        dispatch({type: ARE_OFFERS_LOADED, payload: false})
    }


    return (
        <aside className={`${classes.sidebar} d-none d-md-block`}>
            <div className={classes.sidebar__menu}>
                <div className={classes.sidebar__menu_links}>
                    <NavLink onClick={() => clearOffers()} exact={true} to={`${pid ? `/${pid}` : '/'}`}
                             className={`${classes.sidebar__menu_link} sidebar__menu--svg`}>
                        <span className={classes.tooltip__menu} data-title="Статистика"/>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M29.2146 2.80204C28.663 2.21552 27.888 1.88037 27.078 1.88037C25.4651 1.88037 24.1594 3.18607 24.1594 4.79899C24.1594 5.05734 24.1943 5.31568 24.2641 5.56705L21.1011 7.80838C19.97 6.66328 18.1197 6.64931 16.9746 7.78045C16.1646 8.58342 15.8923 9.7774 16.2833 10.8527L13.3647 13.3105C12.443 11.9908 10.6276 11.6626 9.30097 12.5843C8.79825 12.9334 8.4212 13.4362 8.21871 14.0087L5.79584 13.0242C5.52352 11.4322 4.02232 10.3639 2.43035 10.6362C0.83837 10.9085 -0.229928 12.4097 0.0423832 14.0017C0.314695 15.5937 1.8159 16.662 3.40787 16.3897C4.45523 16.2151 5.32104 15.482 5.67714 14.4835L8.10001 15.468C8.37232 17.06 9.87353 18.1283 11.4655 17.856C12.869 17.6186 13.8954 16.4036 13.8954 14.9792C13.8954 14.8885 13.8884 14.7907 13.8814 14.7L17.0653 12.0118C18.2593 13.094 20.1027 13.0102 21.1849 11.8162C21.8762 11.0552 22.1136 9.98687 21.8203 9.00935L24.9484 6.78896C26.0516 7.96898 27.895 8.02483 29.075 6.92861C30.255 5.8254 30.3109 3.98206 29.2146 2.80204ZM2.91911 15.0421C2.08123 15.0421 1.39696 14.3578 1.39696 13.5199C1.39696 12.6821 2.08123 11.9978 2.91911 11.9978C3.75699 11.9978 4.44126 12.6821 4.44126 13.5199C4.44126 14.3578 3.75699 15.0421 2.91911 15.0421ZM10.9698 16.5084C10.1319 16.5084 9.4476 15.8241 9.4476 14.9862C9.4476 14.1484 10.1319 13.4641 10.9698 13.4641C11.8076 13.4641 12.4919 14.1484 12.4919 14.9862C12.4919 15.8241 11.8146 16.5084 10.9698 16.5084ZM19.0274 11.3833C18.1895 11.3833 17.5052 10.6991 17.5052 9.86119C17.5052 9.02331 18.1895 8.33904 19.0274 8.33904C19.8653 8.33904 20.5495 9.02331 20.5495 9.86119C20.5495 10.6991 19.8653 11.3833 19.0274 11.3833ZM27.078 6.32114C26.2401 6.32114 25.5559 5.63687 25.5559 4.79899C25.5559 3.96111 26.2401 3.27684 27.078 3.27684C27.9159 3.27684 28.6002 3.96111 28.6002 4.79899C28.6002 5.64385 27.9229 6.32114 27.078 6.32114Z"
                                fill="#212F57"/>
                            <path
                                d="M29.0331 10.0358H25.123C24.7389 10.0358 24.4247 10.35 24.4247 10.734V27.352C24.4247 27.736 24.7389 28.0502 25.123 28.0502H29.0331C29.4171 28.0502 29.7313 27.736 29.7313 27.352V10.734C29.7313 10.35 29.4171 10.0358 29.0331 10.0358ZM28.3349 26.7235H25.8212V11.4322H28.3349V26.7235Z"
                                fill="#212F57"/>
                            <path
                                d="M21.0034 15.0631H17.0933C16.7092 15.0631 16.395 15.3773 16.395 15.7613V27.352C16.395 27.736 16.7092 28.0502 17.0933 28.0502H21.0034C21.3874 28.0502 21.7016 27.736 21.7016 27.352V15.7613C21.7016 15.3773 21.3874 15.0631 21.0034 15.0631ZM20.3052 26.7236H17.7915V16.4595H20.3052V26.7236Z"
                                fill="#212F57"/>
                            <path
                                d="M12.9039 20.2998H8.99376C8.60973 20.2998 8.29552 20.614 8.29552 20.998V27.4218C8.29552 27.8058 8.60973 28.12 8.99376 28.12H12.9039C13.2879 28.12 13.6021 27.8058 13.6021 27.4218V20.998C13.6021 20.614 13.2879 20.2998 12.9039 20.2998ZM12.2056 26.7236H9.69199V21.6963H12.2056V26.7236Z"
                                fill="#212F57"/>
                            <path
                                d="M4.87416 18.8335H0.964051C0.580022 18.8335 0.265816 19.1477 0.265816 19.5317V27.4218C0.265816 27.8058 0.580022 28.12 0.964051 28.12H4.87416C5.25819 28.12 5.5724 27.8058 5.5724 27.4218V19.5317C5.5724 19.1477 5.25819 18.8335 4.87416 18.8335ZM4.17593 26.7236H1.66229V20.23H4.17593V26.7236Z"
                                fill="#212F57"/>
                        </svg>
                    </NavLink>
                    <NavLink to="/webs/1" className={`${classes.sidebar__menu_link} sidebar__menu--svg`}>
                        <span className={classes.tooltip__menu} data-title="Вебы"/>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M30 15C30 6.70967 23.2912 0 15 0C6.70967 0 0 6.70876 0 15C0 23.2169 6.65268 30 15 30C23.313 30 30 23.2546 30 15ZM15 1.75781C22.3018 1.75781 28.2422 7.69821 28.2422 15C28.2422 17.6711 27.4477 20.2384 25.9744 22.413C20.0583 16.0492 9.95247 16.0378 4.02557 22.413C2.55226 20.2384 1.75781 17.6711 1.75781 15C1.75781 7.69821 7.69821 1.75781 15 1.75781ZM5.12192 23.8184C10.3821 17.9182 19.6193 17.9196 24.8779 23.8184C19.6001 29.7231 10.402 29.7249 5.12192 23.8184Z"
                                fill="#212F57"/>
                            <path
                                d="M15 16.4314C17.8357 16.4314 20.1429 14.4538 20.1429 12.0232V10.5538C20.1429 8.1232 17.8357 6.14565 15 6.14565C12.1643 6.14565 9.85714 8.1232 9.85714 10.5538V12.0232C9.85714 14.4538 12.1643 16.4314 15 16.4314ZM11.5714 10.5538C11.5714 8.93327 13.1094 7.61503 15 7.61503C16.8906 7.61503 18.4286 8.93327 18.4286 10.5538V12.0232C18.4286 13.6437 16.8906 14.962 15 14.962C13.1094 14.962 11.5714 13.6437 11.5714 12.0232V10.5538Z"
                                fill="#212F57"/>
                        </svg>
                    </NavLink>
                    {/*<NavLink to="/documents" className={classes.sidebar__menu_link}>*/}
                    {/*    <span className={classes.tooltip__menu} data-title="Документы"/>*/}
                    {/*    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*        <g clipPath="url(#clip0_324:5900)">*/}
                    {/*            <path d="M25.4179 6.22664L19.7681 0.881602C19.1671 0.313066 18.3806 0 17.5533 0H6.79688C5.0199 0 3.57422 1.44568 3.57422 3.22266V26.7773C3.57422 28.5543 5.0199 30 6.79688 30H23.2031C24.9801 30 26.4258 28.5543 26.4258 26.7773V8.5677C26.4258 7.68592 26.0584 6.83262 25.4179 6.22664ZM23.7106 7.03125H19.3359C19.1744 7.03125 19.043 6.89982 19.043 6.73828V2.61539L23.7106 7.03125ZM23.2031 28.2422H6.79688C5.98916 28.2422 5.33203 27.5851 5.33203 26.7773V3.22266C5.33203 2.41494 5.98916 1.75781 6.79688 1.75781H17.2852V6.73828C17.2852 7.86908 18.2051 8.78906 19.3359 8.78906H24.668V26.7773C24.668 27.5851 24.0108 28.2422 23.2031 28.2422Z" fill="#212F57"/>*/}
                    {/*            <path d="M21.2695 11.7188H8.37891C7.89352 11.7188 7.5 12.1123 7.5 12.5977C7.5 13.083 7.89352 13.4766 8.37891 13.4766H21.2695C21.7549 13.4766 22.1484 13.083 22.1484 12.5977C22.1484 12.1123 21.7549 11.7188 21.2695 11.7188Z" fill="#212F57"/>*/}
                    {/*            <path d="M21.2695 16.4062H8.37891C7.89352 16.4062 7.5 16.7998 7.5 17.2852C7.5 17.7705 7.89352 18.1641 8.37891 18.1641H21.2695C21.7549 18.1641 22.1484 17.7705 22.1484 17.2852C22.1484 16.7998 21.7549 16.4062 21.2695 16.4062Z" fill="#212F57"/>*/}
                    {/*            <path d="M12.6398 21.0938H8.37891C7.89352 21.0938 7.5 21.4873 7.5 21.9727C7.5 22.458 7.89352 22.8516 8.37891 22.8516H12.6398C13.1252 22.8516 13.5188 22.458 13.5188 21.9727C13.5188 21.4873 13.1252 21.0938 12.6398 21.0938Z" fill="#212F57"/>*/}
                    {/*        </g>*/}
                    {/*        <defs>*/}
                    {/*            <clipPath id="clip0_324:5900">*/}
                    {/*                <rect width="30" height="30" fill="white"/>*/}
                    {/*            </clipPath>*/}
                    {/*        </defs>*/}
                    {/*    </svg>*/}
                    {/*</NavLink>*/}
                    {/*<NavLink to="/banners" className={classes.sidebar__menu_link}>*/}
                    {/*    <span className={classes.tooltip__menu} data-title="Баннеры"/>*/}
                    {/*    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*        <path d="M6.15234 3.57422C4.69846 3.57422 3.51562 4.75705 3.51562 6.21094V9.72656C3.51562 10.212 3.90914 10.6055 4.39453 10.6055C4.87992 10.6055 5.27344 10.212 5.27344 9.72656V8.84766H7.03125V9.72656C7.03125 10.212 7.42477 10.6055 7.91016 10.6055C8.39555 10.6055 8.78906 10.212 8.78906 9.72656V6.21094C8.78906 4.75705 7.60623 3.57422 6.15234 3.57422ZM5.27344 7.08984V6.21094C5.27344 5.72631 5.66771 5.33203 6.15234 5.33203C6.63697 5.33203 7.03125 5.72631 7.03125 6.21094V7.08984H5.27344Z"*/}
                    {/*              fill="#212F57"/>*/}
                    {/*        <path d="M12.3047 3.57422H11.4258C10.9404 3.57422 10.5469 3.96773 10.5469 4.45312V9.72656C10.5469 10.212 10.9404 10.6055 11.4258 10.6055H12.3047C14.2432 10.6055 15.8203 9.02836 15.8203 7.08984C15.8203 5.15133 14.2432 3.57422 12.3047 3.57422ZM12.3047 8.84766V5.33203C13.2739 5.33203 14.0625 6.12059 14.0625 7.08984C14.0625 8.0591 13.2739 8.84766 12.3047 8.84766Z"*/}
                    {/*              fill="#212F57"/>*/}
                    {/*        <path d="M29.1211 14.1211C29.6065 14.1211 30 13.7276 30 13.2422V0.9375C30 0.452109 29.6065 0.0585938 29.1211 0.0585938H0.878906C0.393516 0.0585938 0 0.452109 0 0.9375V13.2422C0 13.7276 0.393516 14.1211 0.878906 14.1211H15.5805L16.0317 15.8789H13.1836C12.6982 15.8789 12.3047 16.2724 12.3047 16.7578V29.0625C12.3047 29.5479 12.6982 29.9414 13.1836 29.9414H29.1211C29.6065 29.9414 30 29.5479 30 29.0625V16.7578C30 16.2724 29.6065 15.8789 29.1211 15.8789H25.7323L22.1381 14.1211H29.1211ZM1.75781 12.3633V1.81641H28.2422V12.3633H18.544L16.3503 11.2904C15.6782 10.9617 14.9268 11.5733 15.1128 12.2984L15.1294 12.3632L1.75781 12.3633ZM28.2422 28.1836H14.0625V17.6367H16.4828L17.7938 22.7454C17.9544 23.3709 18.7143 23.6155 19.2096 23.2007L20.4602 22.1529L21.7924 23.7499C22.1033 24.1226 22.659 24.1734 23.0323 23.8602L26.0631 21.317C26.4342 21.0056 26.4833 20.4527 26.173 20.0807L24.8385 18.4809L25.8461 17.6367H28.2422V28.1836ZM22.9267 18.9334L24.2617 20.5338L22.5775 21.9471L21.2457 20.3506C20.9337 19.9767 20.378 19.9284 20.0064 20.2399L19.1497 20.9576L17.2888 13.7062L23.9258 16.9522L23.0371 17.6967C22.6657 18.0079 22.6162 18.5612 22.9267 18.9334Z"*/}
                    {/*              fill="#212F57"/>*/}
                    {/*        <path d="M9.66797 15.8789H0.878906C0.393516 15.8789 0 16.2724 0 16.7578V29.0625C0 29.5479 0.393516 29.9414 0.878906 29.9414H9.66797C10.1534 29.9414 10.5469 29.5479 10.5469 29.0625V16.7578C10.5469 16.2724 10.1534 15.8789 9.66797 15.8789ZM8.78906 28.1836H1.75781V17.6367H8.78906V28.1836Z"*/}
                    {/*              fill="#212F57"/>*/}
                    {/*        <path d="M18.457 6.21094H25.4883C25.9737 6.21094 26.3672 5.81742 26.3672 5.33203C26.3672 4.84664 25.9737 4.45312 25.4883 4.45312H18.457C17.9716 4.45312 17.5781 4.84664 17.5781 5.33203C17.5781 5.81742 17.9716 6.21094 18.457 6.21094Z"*/}
                    {/*              fill="#212F57"/>*/}
                    {/*        <path d="M18.457 9.72656H25.4883C25.9737 9.72656 26.3672 9.33305 26.3672 8.84766C26.3672 8.36227 25.9737 7.96875 25.4883 7.96875H18.457C17.9716 7.96875 17.5781 8.36227 17.5781 8.84766C17.5781 9.33305 17.9716 9.72656 18.457 9.72656Z"*/}
                    {/*              fill="#212F57"/>*/}
                    {/*    </svg>*/}
                    {/*</NavLink>*/}
                    {/*<NavLink to="/history" className={classes.sidebar__menu_link}>*/}
                    {/*    <span className={classes.tooltip__menu} data-title="История ставок"/>*/}
                    {/*    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*        <path d="M20.3446 17.6488L16.1617 14.5117V8.12139C16.1617 7.47885 15.6423 6.95947 14.9998 6.95947C14.3573 6.95947 13.8379 7.47885 13.8379 8.12139V15.0928C13.8379 15.4587 14.0099 15.8038 14.3027 16.0223L18.9502 19.508C19.1593 19.6648 19.4034 19.7404 19.6462 19.7404C20.0006 19.7404 20.3491 19.5812 20.5769 19.2744C20.9627 18.762 20.8581 18.0335 20.3446 17.6488Z"*/}
                    {/*              fill="#212F57"/>*/}
                    {/*        <path d="M15 0C6.7285 0 0 6.7285 0 15C0 23.2715 6.7285 30 15 30C23.2715 30 30 23.2715 30 15C30 6.7285 23.2715 0 15 0ZM15 27.6762C8.01123 27.6762 2.32377 21.9888 2.32377 15C2.32377 8.01123 8.01123 2.32377 15 2.32377C21.9899 2.32377 27.6762 8.01123 27.6762 15C27.6762 21.9888 21.9888 27.6762 15 27.6762Z"*/}
                    {/*              fill="#212F57"/>*/}
                    {/*    </svg>*/}
                    {/*</NavLink>*/}
                </div>
                <div onClick={() => props.changeSidebar()}
                     className={`${classes.hide__menu_icon} ${props.ActiveSidebar ? classes.toggled : ""} d-flex align-items-center justify-content-center`}>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.4395 15C10.4395 14.7132 10.5489 14.4265 10.7675 14.2079L17.6472 7.32823C18.0848 6.89059 18.7944 6.89059 19.2318 7.32823C19.6693 7.76569 19.6693 8.4751 19.2318 8.91277L13.1443 15L19.2316 21.0872C19.6691 21.5249 19.6691 22.2342 19.2316 22.6716C18.7942 23.1095 18.0846 23.1095 17.647 22.6716L10.7672 15.7921C10.5487 15.5733 10.4395 15.2866 10.4395 15Z"
                            fill="#212F57"/>
                    </svg>
                </div>
            </div>
        </aside>

    )
}

export default Sidebar;
