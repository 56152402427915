import {getTranslatedWord} from "../../store/actions";
import {GENERAL} from "../../store/types";

const path = GENERAL
export const required = value => {
    if (value) return undefined;
    return getTranslatedWord(path, 'Поле обязательное')
}

export const minLengthCreator = minLength => value => {
    if (value && value.length < minLength)
        return getTranslatedWord(path, `Мин. символов: ${minLength}`)
    return undefined;
}

export const maxLengthCreator = maxLength => value => {
    if (value && value.length > maxLength)
        return getTranslatedWord(path, `Макс. символов: ${maxLength}`)
    return undefined;
}