import {
    ARE_OFFERS_LOADED,
    CLEAR_EXPORT_LINK_AND_PROGRESS,
    GET_HOME_OFFER, IS_PRELOADER_ACTIVE,
    RESPONSE_SUPPORT_SUCCESS,
    SET_EXPORT_POSTBACK_LINK,
    SET_PROGRESS_MAIN,
    TOGGLE_IS_FETCHING,
} from './types'

const initialState = {
    offers: {},
    total: 0,
    chartDataState: {},
    isFetching: false,
    link: "",
    progress: 0,
    isRequestSupportSent: false,
    max: 0,
    isPreloaderActive: true,
    areOffersLoaded: false
};

export const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HOME_OFFER:
            return {...state, offers: action.payload.offers, total: action.payload.total, max: action.payload.max};
        case TOGGLE_IS_FETCHING:
            return {...state, isFetching: action.isFetching}
        case SET_EXPORT_POSTBACK_LINK:
            state.link = action.payload;
            return {...state}
        case SET_PROGRESS_MAIN:
            return {...state, progress: action.payload}
        case CLEAR_EXPORT_LINK_AND_PROGRESS:
            return {...state, link: "", progress: 0}
        case RESPONSE_SUPPORT_SUCCESS:
            return {...state, isRequestSupportSent: action.payload}
        case IS_PRELOADER_ACTIVE:
            return {...state, isPreloaderActive: action.payload}
        case ARE_OFFERS_LOADED:
            return {...state, areOffersLoaded: action.payload}
        default:
            return state;
    }
};
