import React from 'react'
import style from './LoginPage.module.css'
import styles from '../../components/FormControls/FormControls.module.css'
import {Field, reduxForm} from "redux-form";
import {Input} from "../../components/FormControls/FormControls";
import {required} from "../../utils/validators/validators";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {loginThunkCreator} from "../../store/actions";

const LoginPage = (props) => {
    const isAuth = useSelector(state => state.auth.isAuth)
    const dispatch = useDispatch()

    const onSubmit = (formData) => {
        dispatch(loginThunkCreator(formData.username, formData.password))
    }


    if (isAuth) {
        return <Redirect to={'/'}/>
    }



    return (
        <div className={style.wrapper}>
            <div className={style.card}>
                <div className={style.cardBody}>
                    <p className={style.cardMsg}>Войдите в аккаунт</p>
                    <LoginReduxForm onSubmit={onSubmit}/>
                </div>

            </div>
        </div>
    )
}

const LoginForm = (props) => {
    return (
        <form onSubmit={props.handleSubmit}>
            <div>
                <Field
                    className='col-12'
                    name='username'
                    placeholder='Логин'
                    validate={[required]}
                    component={Input}
                />
            </div>
            <div>
                <Field
                    className='col-12'
                    name='password'
                    type='password'
                    placeholder='Пароль'
                    validate={[required]}
                    component={Input}
                />
            </div>

            {props.error &&
                <div className={styles.formSummaryError}>
                    {props.error}
                </div>
            }

            <div className='d-flex justify-content-end '>
                    <button className='d-flex w-100 justify-content-center'>Войти</button>
            </div>

        </form>
    )
}


const LoginReduxForm = reduxForm({form: 'login'})(LoginForm)

export default LoginPage;