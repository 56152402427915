import HomePage from '../../views/HomePage/HomePage';
import WebsPage from '../../views/WebsPage/WebsPage';
import DocumentsPage from '../../views/DocumentsPage/DocumentsPage';
import BannersPage from '../../views/BannersPage/BannersPage';
import HistoryPage from '../../views/HistoryPage/HistoryPage';
import Page404 from "../../views/Page404/Page404";
import LoginPage from "../../views/LoginPage/LoginPage";


export default {
    HomePage,
    WebsPage,
    DocumentsPage,
    BannersPage,
    HistoryPage,
    Page404,
    LoginPage
}