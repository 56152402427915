import Modal from "react-bootstrap/Modal";
import {getTranslatedWord, requestSupportThunk} from "../../../../store/actions";
import classes from "./ModalSupport.module.css";
import React from "react";
import {GENERAL} from "../../../../store/types";
import {Field, reduxForm} from "redux-form";
import {maxLengthCreator, minLengthCreator, required} from "../../../../utils/validators/validators";
import {Textarea} from "../../../FormControls/FormControls";
import {useDispatch, useSelector} from "react-redux";

const path = GENERAL
const minLength3 = minLengthCreator(3)
const maxLength200 = maxLengthCreator(200)


const ModalSupport = ({show, onHide}) => {
    const dispatch = useDispatch()
    const isRequestSupportSent = useSelector(state => state.main.isRequestSupportSent)
    const onSubmit = (text) => {
        dispatch(requestSupportThunk(text.message))
    }

    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <h3>{getTranslatedWord(path, 'Запрос в техподдержку')}</h3>
            {isRequestSupportSent ?
                <>
                    <p>{getTranslatedWord(path, 'Запрос был успешно отправлен')}</p>
                    <button className={classes.buttonOk} onClick={() => onHide(false)}>Ok</button>
                </>

                :
                <>
                    <p>{getTranslatedWord(path, 'Отправьте запрос. Мы свяжемся с вами, чтобы предоставить интересующую информацию.')}</p>
                    <ModalSupportReduxForm onSubmit={onSubmit}/>
                </>

            }

        </Modal.Body>
    </Modal>
}

const ModalSupportForm = (props) => {

    return (
        <form onSubmit={props.handleSubmit}>
            {/*<Field*/}
            {/*    component='select'*/}
            {/*    name="type"*/}
            {/*    className="request__type">*/}
            {/*    <option value='Выберите тип запроса'*/}
            {/*            disabled>{getTranslatedWord(path, 'Выберите тип запроса')}</option>*/}
            {/*    <option value="Блокировка">{getTranslatedWord(path, 'Блокировка')}</option>*/}
            {/*    <option value="Индивидуальная ставка">{getTranslatedWord(path, 'Индивидуальная ставка')}</option>*/}
            {/*    <option value="Источники трафика">{getTranslatedWord(path, 'Источники трафика')}</option>*/}
            {/*</Field>*/}
            <Field
                validate={[required, minLength3, maxLength200]}
                component={Textarea}
                name="message"
                className={classes.request__message}
                placeholder={getTranslatedWord(path, 'Ваше сообщение')}/>
            <button className="primary-button">{getTranslatedWord(path, 'Отправить запрос')}</button>
        </form>
    )
}


const ModalSupportReduxForm = reduxForm({form: 'support'})(ModalSupportForm)


export default ModalSupport;
