import {
    CLEAR_EXPORT_LINK_AND_PROGRESS,
    IS_WEB_BLOCKED,
    SET_EXPORT_WEBS_LINK,
    SET_IS_LOADED,
    SET_PROGRESS_WEBS,
    SET_WEBS, SET_WEBS_TOTAL
} from './types'

const initialState = {
    webs: [],
    total: 0,
    isLoaded: false,
    link: "",
    progress: 0,
    isWebBlocked: false,
    max: 1,
    websTotal: {
        clicks: 0,
        leads: 0,
        ecpc: 0,
        cr: 0
    }
};

export const websReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WEBS:
            return {...state, webs: action.payload.webs, total: action.payload.total, max: action.payload.max}
        case SET_IS_LOADED:
            return {...state, isLoaded: action.payload}
        case SET_EXPORT_WEBS_LINK:
            state.link = action.payload;
            return {...state}
        case SET_PROGRESS_WEBS:
            return {...state, progress: action.payload}
        case CLEAR_EXPORT_LINK_AND_PROGRESS:
            return {...state, link: "", progress: 0}
        case IS_WEB_BLOCKED:
            return {...state, isWebBlocked: action.payload}
        case SET_WEBS_TOTAL:
            return {
                ...state, websTotal: action.payload
            }
        default:
            return state;
    }
};