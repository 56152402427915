import React, {useEffect, useRef} from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from "moment";
import {useDispatch} from "react-redux";
import {getTranslatedWord, setPickedDate} from "../../store/actions";
import {
    ARE_OFFERS_LOADED,
    CLEAR_EXPORT_LINK_AND_PROGRESS, GENERAL, IS_PRELOADER_ACTIVE, SET_IS_LOADED,
} from "../../store/types";

const DatePicker = ( {disabled = true,  ...props} ) => {
    const dispatch = useDispatch()
    let start = props.startDate.split('-')
    let formattedStart = [start[0], start[1], start[2]] = [start[1], start[2], start[0]].join('/')
    let end = props.endDate.split('-')
    let formattedEnd = [end[0], end[1], end[2]] = [end[1], end[2], end[0]].join('/')
    let today = moment();
    const path = GENERAL
    let newRanges = {}
    let ranges = {
        Сегодня: [moment(), moment()],
        Вчера: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
        'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
        'Текущий месяц': [moment().startOf('month'), moment()],
        'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],

    }

    for (let key in ranges) {
        newRanges[getTranslatedWord(path, key)] = ranges[key]
    }

    function handleCallback(start, end) {

        const startDate = start.format("YYYY-MM-DD")
        const endDate = end.format("YYYY-MM-DD")
        localStorage.setItem('start', startDate)
        localStorage.setItem('end', endDate)
        dispatch(setPickedDate(startDate, endDate))
        dispatch({type: SET_IS_LOADED, payload: false})
        dispatch({type: ARE_OFFERS_LOADED, payload: false})
        dispatch({type: CLEAR_EXPORT_LINK_AND_PROGRESS})
        dispatch({
            type: IS_PRELOADER_ACTIVE,
            payload: true
        })
    }



    return (
        <div className={`${!disabled ? 'not-allowed' : ''}`}>
            <DateRangePicker onCallback={handleCallback} initialSettings={{
                showDropdowns: true,
                opens: 'left',
                autoApply: true,
                startDate: formattedStart,
                endDate: formattedEnd,
                alwaysShowCalendars: true,
                showCustomRangeLabel: false,
                minDate: '08/01/2019',
                maxDate: today,
                ranges: newRanges,
                maxSpan: {
                    'days': 365
                },

            }}
            >

                <input type="text" className={`stat-select-wrap ${!disabled ? 'events-none' : ''}`}/>


            </DateRangePicker>
        </div>

    )
};


export default DatePicker;